/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/profile.css";
import profile from "../../assets/images/generals/profilep.png";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderDashboard from "../../components/HeaderDashboard";
import { FiCheckCircle, FiEdit } from "react-icons/fi";
import DataContext from "../../context/dataContext";
import { urlService, DashboardURL } from "../../services/UrlService";
import moment from "moment";
import Renderif from "../../components/Renderif";
import AuthContext from "../../context/authContext";

function ProfileData() {
	const navigate = useNavigate();

	const { getData, data, updateUserInfo, setUpdateUserInfo } =
		useContext(DataContext);

	const { user } = useContext(AuthContext);

	useEffect(() => {}, []);

	return (
		<>
			<div className="pcs__center_content">
				<div className="section_title text-white psc__section_title">
					Perfil
				</div>

				<div className="profile_section bg-dark-blue-secondary">
					<div className="subcontent">
						<div className="section_photo">
							<div className="photo">
								<img
									src={
										urlService +
										"/images/users/" +
										user?.photo
									}
									alt="foto"
								/>
							</div>
						</div>

						<div className="section_info">
							<div className="user_info">
								<div className="user_name psc__section_subtitle text-white">
									{user?.fullname}
									<span>|</span>
								</div>

								<div className="user_rol">
									<div className="icon">
										<FiCheckCircle />
									</div>

									<div className="rol psc__text_normal text-gray ">
										<Renderif isTrue={user?.rol === 1}>
											Administrador
										</Renderif>

										<Renderif isTrue={user?.rol === 2}>
											Capturista
										</Renderif>
									</div>

									<div className="clear"></div>
								</div>

								<div className="clear"></div>
							</div>

							<div className="user_data">
								<div className="data_info psc__text_normal text-gray mb-10">
									{user?.email}
								</div>
								<div className="data_info psc__text_normal text-gray mb-10">
									{user?.phone}
								</div>

								<div className="data_info psc__text_normal text-gray mb-10">
									Ciudad de México, Último Registro:{" "}
									{moment(Date.now()).format("DD/MM/YYYY")}{" "}
								</div>
							</div>
						</div>

						<div
							className="user_edit"
							onClick={() => setUpdateUserInfo(!updateUserInfo)}
						>
							<div className="icon bg-purple">
								<FiEdit />
							</div>
						</div>

						<div className="clear"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ProfileData;
