import React, {useContext} from "react";
import { Modal, Button } from "antd";
import ComponentContext from "../../../context/componentContext";
import ReportForm from "../../forms/reports";

export default function ModalReport() {

    const { stateComp, setStateComp, modalReport } =
    useContext(ComponentContext);


    const closeModal = () => {
        setStateComp({
			modalReport: false,
		});

    }
  return <Modal
    title="Generar Reporte de sellos"
    visible={modalReport}
    onCancel={closeModal}
    cancelButtonProps={{
        style: { display: "none" },
    }}
    okButtonProps={{
        style: { display: "none" },
    }}
>
    <main className="Reports__section">

        <ReportForm />
    </main>
</Modal>;
}
