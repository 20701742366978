/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

import "./App.css";
import "./assets/css/grids.css";
import "./assets/css/fonts.css";
import "./assets/css/titles.css";
import "./assets/css/colors.css";
import "./assets/css/forms.css";
import "./assets/css/generals.css";
import "./assets/css/antdCustom.css";
import "./assets/css/buttons.css";
import "./assets/css/modalCreate.css";
import "./assets/css/reports.css";
import { Layout } from "antd";
import AuthState from "./states/authState";
import AuthContext from "./context/authContext";
import InfoState from "./states/infoState";
import Renderif from "./components/Renderif";
import DataState from "./states/dataState";
import ComponentState from "./states/componentState";
import SealsState from "./states/sealsState";
import UsersState from "./states/userState";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import MainMenu from "./menus/mainMenu";
import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/Profile";
import Rols from "./pages/rols/Rols";
import SealsOwners from "./pages/seal-owners/SealsOwners";
import Users from "./pages/users/Users";
import Seals from "./pages/seals/Seals";
import SealsDetails from "./pages/seals/SealsDetails";
import UserVerify from "./pages/users/UserVerify";
import PasswordRecovery from "./pages/password/passwordRecovery";
import ModalMessage from "./components/modals/response/MessageModal";

const { Sider, Content } = Layout;

function PrivateRoute({ children }) {
	const { user } = useContext(AuthContext);
	return user ? children : <Navigate to="/" />;
}

function App() {
	const [toggle, setToggle] = useState(false);
	const [initSiderBar, setInitSiderBar] = useState(false);

	useEffect(() => {
		return () => {};
	});

	return (
		<>
			<AuthState>
				<ComponentState>
					<DataState>
						<SealsState>
							<UsersState>
								<Layout className="bg-dark-blue-primary">
									<Router>
										<ModalMessage />
										<MainMenu />

										<Content>
											<Routes>
												<Route
													exact
													path="/"
													element={
														<Login />
													}
												/>
												<Route
													exact
													path="/user/verify/"
													element={
														<UserVerify />
													}
												/>
												<Route
													exact
													path="/password/recovery/"
													element={
														<PasswordRecovery />
													}
												/>
											</Routes>
											<RolRouter></RolRouter>
										</Content>
									</Router>
								</Layout>
							</UsersState>
						</SealsState>
					</DataState>
				</ComponentState>
			</AuthState>
		</>
	);
}

function AdminRoutes() {
	return (
		<>
			<Routes>
				<Route
					exact
					path="/dashboard"
					element={
						<PrivateRoute>
							<SealsState>
								<InfoState>
									<Dashboard />
								</InfoState>
							</SealsState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/profile"
					element={
						<PrivateRoute>
							<Profile />
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/rols"
					element={
						<PrivateRoute>
							<Rols />
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/seals-owners"
					element={
						<PrivateRoute>
							<DataState>
								<SealsOwners />
							</DataState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/users"
					element={
						<PrivateRoute>
							<Users />
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/seals"
					element={
						<PrivateRoute>
							<InfoState>
								<Seals />
							</InfoState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/seals/detail/"
					element={
						<PrivateRoute>
							<SealsDetails />
						</PrivateRoute>
					}
				/>
			</Routes>
		</>
	);
}

function CapturisRoutes() {
	return (
		<>
			<Routes>
				<Route
					exact
					path="/dashboard"
					element={
						<PrivateRoute>
							<InfoState>
								<Dashboard />
							</InfoState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/profile"
					element={
						<PrivateRoute>
							<Profile />
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/rols"
					element={
						<PrivateRoute>
							<Rols />
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/seals-owners"
					element={
						<PrivateRoute>
							<DataState>
								<SealsOwners />
							</DataState>
						</PrivateRoute>
					}
				/>

				<Route
					exact
					path="/seals"
					element={
						<PrivateRoute>
							<InfoState>
								<SealsState>
									<Seals />
								</SealsState>
							</InfoState>
						</PrivateRoute>
					}
				/>
				<Route
					exact
					path="/seals/detail/"
					element={
						<PrivateRoute>
							<SealsDetails />
						</PrivateRoute>
					}
				/>
			</Routes>
		</>
	);
}

function RolRouter() {
	const { user } = useContext(AuthContext);

	return (
		<>
			<Renderif isTrue={user?.rol === 1}>
				<AdminRoutes></AdminRoutes>
			</Renderif>
			<Renderif isTrue={user?.rol === 2}>
				<CapturisRoutes></CapturisRoutes>
			</Renderif>
			<Renderif isTrue={user?.rol === 3}></Renderif>
		</>
	);
}

export default App;
