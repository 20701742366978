/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from "../actions/dataActions";

export const dataInitialState = {
	data: [],
	data_update: [],
	data_countries: [],
	data_revisions: [],
	data_incidentes: [],
	data_rols: [],
	data_seals_types: [],
	data_seals_owners: [],
	data_seals_status: [],
	data_user: [],
};

export function dataReducer(state, action) {
	const { payload, type } = action;
	switch (type) {
		case TYPES.GET_DATA:
			return {
				...state,
				data: payload,
			};
		case TYPES.PUT_DATA:
			return {
				...state,
				data_update: payload,
			};
		case TYPES.GET_DATA_COUNTRIES:
			return {
				...state,
				data_countries: payload,
			};
		case TYPES.GET_DATA_ROLS:
			return {
				...state,
				data_rols: payload,
			};
		case TYPES.GET_DATA_INCIDENTS:
			return {
				...state,
				data_incidentes: payload,
			};

		case TYPES.GET_DATA_SEALS_TYPES:
			return {
				...state,
				data_seals_types: payload,
			};
		case TYPES.GET_DATA_SEALS_OWNERS:
			return {
				...state,
				data_seals_owners: payload,
			};
		case TYPES.GET_DATA_SEALS_STATUS:
			return {
				...state,
				data_seals_status: payload,
			};

		case TYPES.GET_DATA_USER:
			return {
				...state,
				data_user: payload,
			};
		case TYPES.POST_DATA:
			return {
				...state,
			};
		case TYPES.GET_REVISION_BY_SEAL_ID:
			return {
				...state,
				data_revisions: payload,
			};
		default:
	}
}
