/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/login.css";
import Animation from "./Animation";
import LoginForm from "./LoginForm";

import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import ModalUpdatePassword from "../../components/modals/login/ModalUpdatePassword";
import AuthContext from "../../context/authContext";

function Login() {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);

	const [userInfo, setUserInfo] = useState([]);

	useEffect(() => {
		if (user) {
			if (user?.rol === 1) {
				navigate("/dashboard");
			}
		}

		return () => {};
	}, [user]);

	return (
		<>
			<section className="psc__login">
				<ModalUpdatePassword />
				<Animation></Animation>
				<LoginForm></LoginForm>
			</section>
		</>
	);
}

export default Login;
