/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import { HiOutlineTicket } from "react-icons/hi";
import { GiBattleship } from "react-icons/gi";
import { BsCheck2Circle } from "react-icons/bs";

import SealsState from "../../context/sealsContext";

import "antd/dist/antd.css";

function SealsInfo() {
	const { stateSeals, setStateSeals } = useContext(SealsState);

	useEffect(() => {}, []);

	return (
		<>
			<div className="section_title text-white psc__section_title">
				{stateSeals?.sealAllData?.sealowner}
			</div>

			<div className="section_seals_data">
				<div className="block">
					<div className="icon bg-purple">
						<HiOutlineTicket />
					</div>
					<div className="info psc__text_normal">
						{stateSeals?.sealAllData?.generate_seals} Sellos
						generados
					</div>
					<div className="clear"></div>
				</div>

				<div className="block">
					<div className="icon bg-sky-blue">
						<GiBattleship />
					</div>
					<div className="info psc__text_normal">
						{stateSeals?.sealAllData?.transit_seals} Sellos en
						tránsito
					</div>
					<div className="clear"></div>
				</div>

				<div className="block">
					<div className="icon bg-green">
						<BsCheck2Circle />
					</div>
					<div className="info psc__text_normal">
						{stateSeals?.sealAllData?.complete_seals} Sellos
						Completados
					</div>
					<div className="clear"></div>
				</div>

				<div className="clear"></div>
			</div>
		</>
	);
}

export default SealsInfo;
