/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/profile.css";

import { useNavigate } from "react-router-dom";
import DataContext from "../../context/dataContext";
import UpdatePaswordModalRecovery from "../../components/modals/login/UpdatePaswordModalRecovery";

function PasswordRecovery() {
	const navigate = useNavigate();

	const { updateUserInfo, data } = useContext(DataContext);

	useEffect(() => {}, []);

	return (
		<>
			<UpdatePaswordModalRecovery />
			<div></div>
		</>
	);
}

export default PasswordRecovery;
