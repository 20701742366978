/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Service } from "../services/api";
import Renderif from "../components/Renderif";
import SealsContext from "../context/sealsContext";
import UsersContext from "../context/usersContext";
import ComponentContext from "../context/componentContext";

function SearchForm(props) {
	const placeholder = props.placeholder;
	const background = props.background;
	const borderColor = props.borderColor;
	const color = props.color;
	const width = props.width;
	const height = props.height;
	const endpoint = props.endpoint;

	const { setStateSeals, stateSeals } = useContext(SealsContext);
	const { userState, setUserState } = useContext(UsersContext);
	const { setStateComp } = useContext(ComponentContext);

	const [search, setSearch] = useState("");
	const [state, setState] = useState({
		sealsSearch: "",
		usersSearch: "",
		initMessage: false,
		initMessageUsers: false,
	});

	const handleChange = async (event) => {
		if (event.target.name === "seals") {
			//const onlyText = event.target.value.replace(/[^a-zA-Z' ']/gi, "");
			let onlyText = event.target.value.replace(/[^A-Za-z0-9' ']/gi, "");

			console.log("onlyText", onlyText);

			setState({
				...state,
				sealsSearch: onlyText.toUpperCase(),
			});

			if (onlyText.length > 1) {
				let searchData = {
					filter: onlyText,
				};

				let _seals = await Service("POST", "seals/search", searchData);
				let sealsData = _seals.data.response;

				console.log("sealsData", sealsData);

				if (_seals.data.response) {
					console.log("sealsData", sealsData);

					sealsData.map((k) => {
						k["key"] = k.id;
						return true;
					});

					setStateSeals({
						...stateSeals,
						seals: sealsData,
						updateStateForm: !stateSeals.updateStateForm,
					});
				}

				if (
					sealsData === undefined &&
					!state.initMessage &&
					onlyText.length > 5
				) {
					setStateComp({
						modalStatus: "404",
						modalMessageTitle: "Sin resultados",
						modalMessageDescription: `No se encontró ningún resultado en sellos para la búsqueda: ${state.sealsSearch}`,
						modalMessage: true,
					});

					setTimeout(() => {
						setState({
							...state,
							sealsSearch: "",
						});
					}, 1000);
				}
			} else if (onlyText.length === 0) {
				setStateSeals({
					...stateSeals,
					updateState: !stateSeals.updateState,
				});

				setState({
					...state,
					initMessage: false,
					sealsSearch: "",
				});
			}
		}

		if (event.target.name === "users") {
			console.log("users ****");
			// const onlyText = event.target.value.replace(
			// 	/[^A-Za-z0-9' ']/gi,
			// 	""
			// );

			setState({
				...state,
				usersSearch: event.target.value,
			});

			if (event.target.value.length > 1) {
				let searchData = {
					filter: event.target.value,
				};

				let _users = await Service("POST", "users/search", searchData);
				let usersData = _users.data.response;

				if (_users.data.response) {
					setUserState({
						...userState,
						users: usersData,
						updateStateForm: !userState.updateStateForm,
					});
				}

				if (
					usersData === undefined &&
					!state.initMessageUsers &&
					event.target.value.length > 8
				) {
					setStateComp({
						modalStatus: "404",
						modalMessageTitle: "Sin resultados",
						modalMessageDescription: `No se encontró ningún resultado en usuarios para la búsqueda: ${state.usersSearch}`,
						modalMessage: true,
					});

					setTimeout(() => {
						setState({
							...state,
							usersSearch: "",
						});
					}, 1000);
				}
			} else if (event.target.value.length === 0) {
				setUserState({
					...userState,
					updateState: !userState.updateState,
				});
			}
		}
	};

	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			<div className="PSC__Search">
				<div className="icon">
					<BiSearch />
				</div>

				<Renderif isTrue={endpoint === "seals"}>
					<input
						style={{
							width: width,
							height: height,
							background: background,
							color: color,
							border: `solid 1px ${borderColor}`,
						}}
						value={state.sealsSearch}
						type="text"
						name="seals"
						placeholder={placeholder}
						onChange={handleChange}
					/>
				</Renderif>

				<Renderif isTrue={endpoint === "users"}>
					<input
						style={{
							width: width,
							height: height,
							background: background,
							color: color,
							border: `solid 1px ${borderColor}`,
						}}
						value={state.usersSearch}
						type="text"
						name="users"
						placeholder={placeholder}
						onChange={handleChange}
					/>
				</Renderif>

				<Renderif isTrue={endpoint === "other"}>
					<input
						style={{
							width: width,
							height: height,
							background: background,
							color: color,
							border: `solid 1px ${borderColor}`,
							keyboardType: "ascii-capable",
						}}
						value={search}
						type="text"
						name="other"
						placeholder={placeholder}
						onChange={handleChange}
					/>
				</Renderif>
			</div>
		</>
	);
}

export default SearchForm;
