/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit, FiUnlock } from "react-icons/fi";
import { TbLock } from "react-icons/tb";
import { Space } from "antd";
import Renderif from "../../components/Renderif";
import UsersContext from "../../context/usersContext";
import ComponentContext from "../../context/componentContext";
import DataContext from "../../context/dataContext";

function UserActions(props) {
	const {
		setModalActionSeal,
		setModalActionInfo,
		setActionID,
		actionID,
		serUptdateUser,
	} = useContext(DataContext);

	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { userState, setUserState } = useContext(UsersContext);

	const ActionUsers = (type, ID) => {
		console.log("delete", type, ID);

		setActionID(ID);

		if (type === 1) {
			//setModalActionSeal(true);

			// setModalActionInfo({
			// 	message: "¿Estás seguro que deseas eliminar este usuario?",
			// 	button: "Eliminar",
			// 	status: 3,
			// 	endpoint: "users/deleteblock",
			// 	endPointRefresh: "users",
			// });

			setStateComp({
				...stateComp,
				userModalAction: true,
				actionInfo: {
					message: "¿Estás seguro que deseas eliminar este usuario?",
					button: "Eliminar",
					status: 3,
					endpoint: "users/deleteblock",
					endPointRefresh: "users",
				},
			});
		}

		if (type === 2) {
			setStateComp({
				...stateComp,
				userModalUpdate: true,
			});
		}

		if (type === 3) {
			// setModalActionSeal(true);
			// setModalActionInfo({
			// 	message: "¿Estás seguro que deseas bloquear este usuario?",
			// 	button: "Bloquear",
			// 	status: 2,
			// 	endpoint: "users/deleteblock",
			// 	endPointRefresh: "users",
			// });

			setStateComp({
				...stateComp,
				userModalAction: true,
				actionInfo: {
					message: "¿Estás seguro que deseas bloquear este usuario?",
					button: "Bloquear",
					status: 2,
					endpoint: "users/deleteblock",
					endPointRefresh: "users",
				},
			});
		}

		if (type === 4) {
			// setModalActionSeal(true);
			// setModalActionInfo({
			// 	message: "¿Estás seguro que deseas desbloquear este usuario?",
			// 	button: "Desbloquear",
			// 	status: 1,
			// 	endpoint: "users/deleteblock",
			// 	endPointRefresh: "users",
			// });

			setStateComp({
				...stateComp,
				userModalAction: true,
				actionInfo: {
					message:
						"¿Estás seguro que deseas desbloquear este usuario?",
					button: "Desbloquear",
					status: 1,
					endpoint: "users/deleteblock",
					endPointRefresh: "users",
				},
			});
		}

		//setModalDeleteUser(true);
	};

	return (
		<>
			<Space size="middle">
				<FaTrashAlt
					className="iconA text-purple"
					onClick={() => ActionUsers(1, props.ID)}
				/>
				<FiEdit
					className="iconA text-purple"
					onClick={() => ActionUsers(2, props.ID)}
				/>

				{props.status === "Bloqueado" ? (
					<>
						<FiUnlock
							className="iconA text-purple"
							onClick={() => ActionUsers(4, props.ID)}
						/>
					</>
				) : (
					<>
						<TbLock
							className="iconA text-purple"
							onClick={() => ActionUsers(3, props.ID)}
						/>
					</>
				)}
			</Space>
		</>
	);
}

export default UserActions;

export const UsersColumns = [
	{
		title: "Nombre",
		dataIndex: "firstname",
		key: "firstname",
	},

	{
		title: "Apellido",
		dataIndex: "lastname",
		key: "lastname",
	},
	{
		title: "Correo Electrónico",
		dataIndex: "email",
		key: "email",
	},
	{
		title: "Teléfono",
		dataIndex: "phone",
		key: "phone",
	},

	{
		title: "Rol",
		dataIndex: "rol_name",
		key: "rol_name",
		render: (rol) => (
			<>
				<Renderif isTrue={rol === "Administrador"}>
					<div className="rols_status rol_admin">{rol}</div>
				</Renderif>

				<Renderif isTrue={rol === "Capturista"}>
					<div className="rols_status rol_capt">{rol}</div>
				</Renderif>

				<Renderif isTrue={rol === "Checker"}>
					<div className="rols_status rol_checker">{rol}</div>
				</Renderif>
			</>
		),
	},

	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (status) => (
			<>
				<Renderif isTrue={status === "Activo"}>
					<div className="users_status user_active">{status}</div>
				</Renderif>

				<Renderif isTrue={status === "Bloqueado"}>
					<div className="users_status user_block">{status}</div>
				</Renderif>

				<Renderif isTrue={status === "Deshabilitado"}>
					<div className="users_status user_disable">{status}</div>
				</Renderif>
			</>
		),
	},
	{
		title: "Acciones",
		key: ["action", "id", "status"],
		render: (text, row) => <UserActions ID={row.id} status={row.status} />,
	},
];
