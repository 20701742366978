/* eslint-disable no-unused-vars */

import React, { useReducer, useState } from "react";
import { dataReducer, dataInitialState } from "../reducers/dataReducer";
import DataContext from "../context/dataContext";
import { urlService } from "../services/UrlService";
import { statusMessage } from "../services/statusMessage";
import moment from "moment";

import axios from "axios";

const DataState = (props) => {
	const [state, dispatch] = useReducer(dataReducer, dataInitialState);
	const [mstate, setMstate] = useState(false);
	const [sealCreateModal, SetSealCreateModal] = useState(false);
	const [sealUpdateModal, setSealUpdateModal] = useState(false);
	const [userCreateModal, SetUserCreateModal] = useState(false);
	const [updateUser, serUptdateUser] = useState(false);
	const [sealDetailsModal, SetSealDetailModal] = useState(false);
	const [updatePasswordModal, SetUpdatePasswordModal] = useState(false);
	const [RevisionDetailsModal, SetRevisionDetailModal] = useState(false);
	const [modalActionSeal, setModalActionSeal] = useState(false);
	const [actionID, setActionID] = useState(null);
	const [revisionNumber, setRevisionNumber] = useState(1);
	const [modalActionInfo, setModalActionInfo] = useState({
		message: "",
		button: "",
		status: "",
		currentSealStatus: "",
		endpoint: "",
		endPointRefresh: "",
	});
	const [selectDefault, SetSelectDefault] = useState(null);
	const [modalMessage, SetModalMessage] = useState(statusMessage[0]);
	const [displaySiderBar, setDisplaySiderBar] = useState(false);
	const [updateState, setUptadeState] = useState(false);
	const [text, setText] = useState("Ejemplo");
	const [updateUserInfo, setUpdateUserInfo] = useState(false);
	const [profileTitle, setProfileTitle] = useState("Perfil");

	const [modalUpdatePassword, setModalUpdatePassword] = useState(false);
	const [modalUpdatePhoto, setModalUpdatePhoto] = useState(false);
	const [modalIncidents, setModalIncidents] = useState(false);
	const [incidentData, setIncidentData] = useState(null);
	const [generalUserInfo, setGeneralUserInfo] = useState([]);
	const [revisionData, setRevisionsData] = useState([]);
	const [sealData, setSealData] = useState([]);
	const [userData, setUserData] = useState([]);
	const [revisionDetail, setRevisionsDetail] = useState([]);

	/* --------------------------------------------------------
    /* ---- GET
    -------------------------------------------------------- */

	const getData = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			console.log("response -->", response);

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k.id;
				return true;
			});

			dispatch({
				type: "GET_DATA",
				payload: info,
			});

			return info;
		}
	};

	/* --------------------------------------------------------
    /* ---- GET
    -------------------------------------------------------- */

	const getDataCountries = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			console.log("response Countries -->", response);

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k.id;
				return true;
			});

			dispatch({
				type: "GET_DATA_COUNTRIES",
				payload: info,
			});
		}
	};

	/* --------------------------------------------------------
    /* ---- GET
    -------------------------------------------------------- */

	const getDataRols = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			console.log("response Rols -->", response);

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k.id;
				return true;
			});

			dispatch({
				type: "GET_DATA_ROLS",
				payload: info,
			});
		}
	};

	/* --------------------------------------------------------
    /* ---- GET DATA GENERAL
    -------------------------------------------------------- */

	const getDataGeneral = async (endpoint, type) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			console.log("response GENERALS -->", response);

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k.id;
				return true;
			});

			if (type === "get_seals_types")
				dispatch({
					type: "GET_DATA_SEALS_TYPES",
					payload: info,
				});
			if (type === "get_country_types")
				dispatch({
					type: "GET_DATA_COUNTRIES",
					payload: info,
				});

			if (type === "get_seals_owners")
				dispatch({
					type: "GET_DATA_SEALS_OWNERS",
					payload: info,
				});

			if (type === "get_seals_status")
				dispatch({
					type: "GET_DATA_SEALS_STATUS",
					payload: info,
				});

			if (type === "get_data_user")
				dispatch({
					type: "GET_DATA_USER",
					payload: info,
				});
		}
	};

	/* --------------------------------------------------------
    /* ---- GET
    -------------------------------------------------------- */

	const getDataIncidents = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			console.log("response Rols -->", response);

			let info = response.data.response;
			info.map((k) => {
				k["key"] = k.id;
				return true;
			});

			dispatch({
				type: "GET_DATA_INCIDENTS",
				payload: info,
			});

			return info;
		}
	};

	/* --------------------------------------------------------
    /* ---- GET SEAL DETAILS BY ID 
    -------------------------------------------------------- */

	const getRevisionsBySealID = async (endpoint) => {
		let hasError = false;
		const response = await axios
			.get(`${urlService}/${endpoint}`)
			.catch(function (error) {
				if (error) {
					hasError = true;
					catchError(error.toJSON());
				}
			});

		if (!hasError) {
			let info = response.data.response;
			console.log("info-->", info);
			/*info.map((k) => {
				k["key"] = k.id;
				return true;
			});*/

			dispatch({
				type: "GET_REVISION_BY_SEAL_ID",
				payload: info,
			});
			console.log("data_revisions -->", dataInitialState.data_revisions);
			return info;
		}
	};

	const DisplayModalCreate = async (type) => {
		switch (type) {
			case "seals":
				SetSealCreateModal(true);
				setUptadeState(!updateState);
				console.log(type, updateState);
				return true;
			case "status":
				setUptadeState(!updateState);
				console.log(type, updateState);
				return true;

			default:
				return false;
		}
	};

	/* --------------------------------------------------------
    /* ---- POST
    -------------------------------------------------------- */

	const postData = async (endpoint, jsonData) => {
		let hasError = false;
		let errorInfo;
		const response = await axios
			.post(`${urlService}/${endpoint}`, jsonData)
			.catch(function (error) {
				errorInfo = error.response.data;
				console.log("ERORR--->", errorInfo);
				hasError = true;
				catchError(error.toJSON());
			});

		if (!hasError) {
			let info = response;
			SetModalMessage(statusMessage[0]);
			setMstate(true);
			dispatch({
				type: "POST_DATA",
				payload: info,
			});
			return info;
		} else {
			return errorInfo;
		}
	};

	const putData = async (endpoint, jsonData) => {
		let hasError = false;
		const response = await axios
			.put(`${urlService}/${endpoint}`, jsonData)
			.catch(function (error) {
				hasError = true;
				catchError(error.toJSON());
			});

		if (!hasError) {
			let info = response;
			SetModalMessage(statusMessage[0]);
			setMstate(true);
			dispatch({
				type: "PUT_DATA",
				payload: info,
			});
			return info;
		}
	};

	/* --------------------------------------------------------
    /* ---- ON SUCCESS MODAL
    -------------------------------------------------------- */

	const showSuccessModal = (val) => {
		setMstate(val);
	};

	/* --------------------------------------------------------
    /* ---- USER TOKEN GENERATO
    -------------------------------------------------------- */

	const rand = () => {
		return Math.random().toString(36).substr(2);
	};

	const UserToken = () => {
		console.log(rand() + rand());
		return rand() + rand();
	};

	/* --------------------------------------------------------
    /* ---- UPDATE PASSWORD DATE
    -------------------------------------------------------- */

	const passwordExpired = () => {
		return moment().add(90, "days").format("YYYY-MM-DD");
	};
	/* --------------------------------------------------------
    /* ---- COMPARE DATES 
    -------------------------------------------------------- */

	const compareDate = (dateValue) => {
		let passwordDate = Date.parse(dateValue);
		var date = new Date(); // today's date and time in ISO format
		let currentDate = Date.parse(date);
		let finalDate = passwordDate - currentDate;
		console.log("finalDate", parseInt(finalDate));

		if (finalDate < 0) {
			console.log("la fecha ya caducó");
			return false;
		} else {
			console.log("la fecha aun no caduca");

			return true;
		}
	};

	/* --------------------------------------------------------
    /* ---- SWITCH: ERROR
    -------------------------------------------------------- */

	const catchError = async (info) => {
		switch (info.status) {
			case 500:
				SetModalMessage(statusMessage[1]);
				setMstate(true);
				return true;
			case 404:
				SetModalMessage(statusMessage[2]);
				setMstate(true);
				return true;
			case 403:
				SetModalMessage(statusMessage[3]);
				setMstate(true);
				return true;

			default:
				return false;
		}
	};

	return (
		<DataContext.Provider
			value={{
				data: state.data,
				getData,
				postData,
				putData,
				getDataGeneral,
				data_countries: state.data_countries,
				data_rols: state.data_rols,
				data_incidentes: state.data_incidentes,
				data_seals_types: state.data_seals_types,
				data_seals_owners: state.data_seals_owners,
				data_seals_status: state.data_seals_status,
				data_user: state.data_user,
				getDataIncidents,
				getDataRols,
				getDataCountries,
				sealCreateModal,
				SetSealCreateModal,
				sealUpdateModal,
				setSealUpdateModal,
				userCreateModal,
				SetUserCreateModal,
				DisplayModalCreate,
				displaySiderBar,
				setDisplaySiderBar,
				setUptadeState,
				updateState,
				setText,
				text,
				sealDetailsModal,
				SetSealDetailModal,
				RevisionDetailsModal,
				SetRevisionDetailModal,
				getRevisionsBySealID,
				updateUserInfo,
				setUpdateUserInfo,
				profileTitle,
				setProfileTitle,
				modalUpdatePassword,
				setModalUpdatePassword,
				setGeneralUserInfo,
				generalUserInfo,
				modalUpdatePhoto,
				setModalUpdatePhoto,
				UserToken,
				compareDate,
				updatePasswordModal,
				SetUpdatePasswordModal,
				passwordExpired,
				modalIncidents,
				setModalIncidents,
				incidentData,
				setIncidentData,
				revisionData,
				setRevisionsData,
				modalActionSeal,
				setModalActionSeal,
				modalActionInfo,
				setModalActionInfo,
				actionID,
				setActionID,
				sealData,
				setSealData,
				userData,
				setUserData,
				updateUser,
				serUptdateUser,
				revisionNumber,
				setRevisionNumber,
				revisionDetail,
				setRevisionsDetail,
			}}
		>
			{props.children}
		</DataContext.Provider>
	);
};

export default DataState;
