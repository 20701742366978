/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal } from "antd";
import DataContext from "../../context/dataContext";
import GeneralButton from "../../components/buttons/GeneralButton";
import { Service } from "../../services/api";
import ComponentContext from "../../context/componentContext";
import SealsContext from "../../context/sealsContext";

var sha1 = require("sha1");
function ModalBlockDelete(props) {
	const { actionID } = useContext(DataContext);
	const { stateSeals, setStateSeals } = useContext(SealsContext);
	const { stateComp, setStateComp } = useContext(ComponentContext);

	const closeModal = () => {
		setStateComp({
			...stateComp,
			sealModalAction: false,
		});
	};

	const deleteSeal = async () => {
		let endPoint = stateComp.actionInfo?.endpoint;
		let status = stateComp.actionInfo?.status;

		let sealSingleDelete = {
			number: stateSeals?.sealData.number,
			type: stateSeals?.sealData.type_id,
			status: stateSeals?.sealData.status_id,
			ID_sealowner: stateSeals?.sealData.owner_id,
			ocr: stateSeals?.sealData.ocr,
		};

		const sealData = {
			id: stateSeals?.sealInfo.id_seal,
			status: status,
		};
		console.log("sealData", sealData);

		let messageInfo = "";
		if (status === 6) {
			messageInfo = "El sello se ha eliminado correctamente";

			let _DeleteSingleSeal = await Service(
				"POST",
				"seals/createDelete",
				sealSingleDelete
			);
		}
		if (status === 5) {
			messageInfo = "El sello se ha bloquedo correctamente";
		}
		if (status === 1) {
			messageInfo = "El sello se ha debloquedo correctamente";
		}

		let _seals = await Service("PUT", endPoint, sealData);
		console.log("===SEALS update====", _seals);

		if (_seals.data.status === 200) {
			closeModal();

			setStateComp({
				modalStatus: "success",
				modalMessageTitle: "Operación realizada con éxito:",
				modalMessageDescription: messageInfo,
				modalMessage: true,
				sealModalCreate: false,
			});

			setStateSeals({
				updateState: !stateSeals.updateState,
			});
		}
	};
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			<Modal
				className="modalMessage"
				visible={stateComp.sealModalAction}
				onCancel={closeModal}
				cancelButtonProps={{
					style: { display: "none" },
				}}
				okButtonProps={{
					style: { display: "none" },
				}}
				centered
			>
				<>
					<div className="mb-20 psc__text_normal text-center">
						{stateComp.actionInfo?.message}
					</div>

					<div className="btn_modal_content">
						<GeneralButton
							className="btn__normal btn__blue transition mr-10"
							title={stateComp.actionInfo?.button}
							onClick={() => deleteSeal()}
						/>

						<GeneralButton
							className="btn__normal btn__blue transition mr-10"
							title="Cancelar"
							onClick={() => closeModal()}
						/>

						<div className="clear"></div>
					</div>
				</>
			</Modal>
		</>
	);
}

export default ModalBlockDelete;
