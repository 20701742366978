/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal } from "antd";
import DataContext from "../../context/dataContext";
import GeneralButton from "../../components/buttons/GeneralButton";
import { Service } from "../../services/api";
import ComponentContext from "../../context/componentContext";
import UsersContext from "../../context/usersContext";

var sha1 = require("sha1");
function ModalBlockDelete(props) {
	const { actionID } = useContext(DataContext);
	const { userState, setUserState } = useContext(UsersContext);
	const { stateComp, setStateComp } = useContext(ComponentContext);

	const closeModal = () => {
		setStateComp({
			...stateComp,
			userModalAction: false,
		});
	};

	const deleteSeal = async () => {
		let endPoint = stateComp.actionInfo?.endpoint;
		let status = stateComp.actionInfo?.status;

		const userInfo = {
			id: userState.userData?.id,
			status: status,
		};
		console.log("userData", userInfo);

		let messageInfo = "";
		if (status === 3) {
			messageInfo = "El usuario se ha eliminado correctamente";
		}
		if (status === 2) {
			messageInfo = "El usuario se ha bloquedo correctamente";
		}
		if (status === 1) {
			messageInfo = "El usuario se ha debloquedo correctamente";
		}

		let _users = await Service("PUT", endPoint, userInfo);
		console.log("===USERS update====", _users);

		if (_users.data.status === 200) {
			closeModal();

			setStateComp({
				modalStatus: "success",
				modalMessageTitle: "Operación realizada con éxito:",
				modalMessageDescription: messageInfo,
				modalMessage: true,
				sealModalCreate: false,
			});

			setUserState({
				updateState: !userState.updateState,
			});
		}
	};
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			<Modal
				className="modalMessage"
				visible={stateComp.userModalAction}
				onCancel={closeModal}
				cancelButtonProps={{
					style: { display: "none" },
				}}
				okButtonProps={{
					style: { display: "none" },
				}}
				centered
			>
				<>
					<div className="mb-20 psc__text_normal text-center">
						{stateComp.actionInfo?.message}
					</div>

					<div className="btn_modal_content">
						<GeneralButton
							className="btn__normal btn__blue transition mr-10"
							title={stateComp.actionInfo?.button}
							onClick={() => deleteSeal()}
						/>

						<GeneralButton
							className="btn__normal btn__blue transition mr-10"
							title="Cancelar"
							onClick={() => closeModal()}
						/>

						<div className="clear"></div>
					</div>
				</>
			</Modal>
		</>
	);
}

export default ModalBlockDelete;
