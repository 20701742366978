/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import DataState from "../../states/dataState";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../../assets/css/modalCreate.css";
import Login from "../../pages/login/Login";
import MainMenu from "../../menus/mainMenu";
import Dashboard from "../../pages/dashboard/Dashboard";
import Profile from "../../pages/profile/Profile";
import Rols from "../../pages/rols/Rols";
import SealsOwners from "../../pages/seal-owners/SealsOwners";
import Users from "../../pages/users/Users";
import Seals from "../../pages/seals/Seals";
import "../../assets/css/dashboard.css";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";

import { Layout } from "antd";
const { Sider, Content } = Layout;

function Home() {
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			<Router>
				<Sider>
					<MainMenu />
				</Sider>

				<Content>
					<Routes>
						<Route
							exact
							path="/dashboard"
							element={
								<DataState>
									<Dashboard />
								</DataState>
							}
						/>

						<Route
							exact
							path="/profile"
							element={
								<DataState>
									<Profile />
								</DataState>
							}
						/>

						<Route
							exact
							path="/rols"
							element={
								<DataState>
									<Rols />
								</DataState>
							}
						/>

						<Route
							exact
							path="/seals-owners"
							element={
								<DataState>
									<SealsOwners />
								</DataState>
							}
						/>

						<Route
							exact
							path="/users"
							element={
								<DataState>
									<Users />
								</DataState>
							}
						/>

						<Route
							exact
							path="/seals"
							element={
								<DataState>
									<Seals />
								</DataState>
							}
						/>
					</Routes>
				</Content>
			</Router>
		</>
	);
}

export default Home;
