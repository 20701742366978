import React, {useState, useContext} from "react";
import { DatePicker, Space } from 'antd';
import Select from 'react-select';
import ComponentContext from "../../../context/componentContext";
import { Service } from "../../../services/api";
const { RangePicker } = DatePicker;


export default function ReportForm() {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const { stateComp, setStateComp } = useContext(ComponentContext);
    const handleChange = selectedOptions => {
        let options = [];
        selectedOptions.map((option) => {
            console.log(`Option selected:`, option.value);
            options.push(option.value);
        });

        setSelectedOptions(options);
    };


    const generateReport = async (data) => {
        let __generateReport = await Service("POST", `reports/generate`, data);
        if(__generateReport.data.status === 200){
            console.log("__generateReport", __generateReport);
            const fileName = __generateReport.data.report_name;
            const url = `https://api.synci.digital/reports/${fileName}.csv` ;
            window.open(url, '_blank');
        }else{
            setStateComp({
				modalStatus: "404",
				modalMessageTitle: "No se han encontrado registros",
				modalMessageDescription: "No se han encontrado registros con ese quiterio de busqueda",
				modalMessage: true,
				// sealModalCreate: false,
			});
        }
    }



    const sealsOptions = [
        { value: 1, label: 'Sin asignar', color: '#00B8D9' },
        { value: 2, label: 'Asignado', color: '#00B8D9' },
        { value: 3, label: 'En tránsito', color: '#00B8D9' },
        { value: 4, label: 'completado', color: '#00B8D9' },
        { value: 5, label: 'Bloqueado', color: '#00B8D9' },
    ];

    return <main>
        <label>Selecciona el rango de fecha</label>
        <RangePicker onChange={(date, dateString)=>{
                    console.log("dateString", dateString)
                    setSelectedDates(dateString);
                }} placeholder={["Fecha de inicio", "Fecha de fin"]}/>
        <label>Seleccione el status de sello</label>

        <div className="select">

            <Select
                isMulti
                placeholder="Selecciona una opción"
                name="colors"
                options={sealsOptions}
                className="basic-multi-select mb-20"
                classNamePrefix="select"
                onChange={(handleChange)}
            />
         
        </div>

        <div className="report__button__container">

        {selectedOptions.length !== 0 && selectedDates.length!==0  ? <div className="report__button transition" onClick={async () => {
							console.log("selectedOptions", selectedOptions)
                                    console.log("selectedDates", selectedDates)

                                    const data = {
                                        "startDate":`${selectedDates[0]}T00:00:00`,
                                        "endDate":`${selectedDates[1]}T23:59:59`,
                                        "sealsStatus": selectedOptions
                                    }
                                    console.log("data", data);

                                
                                    generateReport(data);

                                }}>
                        Generar Reporte
                    </div> : <div className="report__button__false transition">
                        Generar Reporte
                    </div>
        }


            <div className="report__button--cancel transition" onClick={() => {
							setStateComp({
								modalReport: false,
							});
						}}>
                Cancelar
            </div>
        </div>

    </main>;
}
