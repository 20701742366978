/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import "../../assets/css/seals.css";
import { IoMdAdd } from "react-icons/io";
import { Table } from "antd";
import { SealsColumns } from "./ActionsIndividual";
import { Service } from "../../services/api";
import SearchForm from "../../components/SearchForm";
import SealsInfo from "./SealsInfo";
import ActionsGroup from "./ActionsGroup";
import ComponentContext from "../../context/componentContext";
import SealsContext from "../../context/sealsContext";
import AuthContext from "../../context/authContext";
import { FaFileSignature } from "react-icons/fa6";
function SealsTab() {
	const [state, setState] = useState({
		totalCompanies: [],
		generateSeals: [],
		transitSeals: [],
		completeSeals: [],
		seals: [],
	});

	const { stateComp, setStateComp } = useContext(ComponentContext);

	const { setStateSeals, stateSeals, selectedRowKeys, selectedRows } =
		useContext(SealsContext);

	const { user } = useContext(AuthContext);

	const rowSelection = {
		selectedRowKeys,
		selectedRows,
		onChange: (selectedRowKeys, selectedRows) => {
			let sealStatus = selectedRows[selectedRows.length - 1]?.status_id;
			console.log("status_id", sealStatus);

			setStateSeals({
				...stateSeals,
				selectedRowKeys: selectedRowKeys,
				selectedRows: selectedRows,
			});

			console.log("selectedRows", selectedRows);
		},

		getCheckboxProps: (record) => {
			return {
				disabled: record.status_id === 4 || record.status_id === 3, //disable the first 4 rows only
			};
		},
	};

	useEffect(() => {
		const data = async () => {
			let _seals = await Service("GET", "seals");
			console.log("===SEALS ====", _seals);
			let sealsData = _seals.data.response;
			stateSeals.seals = _seals.data.response;
			sealsData.map((k) => {
				k["key"] = k.id;
				return true;
			});

			// setState({
			// 	...state,
			// 	seals: sealsData,
			// });

			let sealOwner = user?.id_sealowner;
			let _sealsData = await Service(
				"GET",
				`seals/alldataSeals/${sealOwner}`
			);
			console.log("===SEALS DATA====", _sealsData);

			setStateSeals({
				...stateSeals,
				seals: sealsData,
				sealAllData: _sealsData.data.response,
			});
		};

		data();
	}, [stateSeals.updateState]);

	useEffect(() => {}, [stateSeals.updateStateForm]);

	return (
		<>
			<div className="pcs__center_content">
				<SealsInfo />
				<div className="btn__container">
					<div
						className="btn__normal_add transition btn__white mb-20"
						onClick={() => {
							setStateComp({
								sealModalCreate: true,
							});
						}}
					>
						<div className="iconAdd text-white">
							<IoMdAdd />
						</div>{" "}
						Alta de sellos
					</div>

					<div
						className="btn__normal_add btn--big transition btn__white mb-20"
						onClick={() => {
							setStateComp({
								modalReport: true,
							});
						}}
					>
						<div className="iconAdd text-white">
							<FaFileSignature />
						</div>{" "}
						Generar Reporte
					</div>
				</div>

				<div className="section_seals_table">
					<div className="data_tables bg-dark-blue-secondary">
						<div className="header">
							<div className="title psc__section_subtitle text-white">
								Lista de Sellos
							</div>
							{/* <div className="filter">Filtrar por</div> */}
							<div className="clear"></div>
						</div>

						<div className="header">
							<div className="search_form_block">
								<SearchForm
									width="100%"
									height="40px"
									placeholder="Buscar sello..."
									color="#9899a1"
									background="#212332"
									borderColor="#212332"
									endpoint="seals"
								/>
							</div>

							<ActionsGroup />
						</div>

						<Table
							rowSelection={rowSelection}
							columns={SealsColumns}
							dataSource={stateSeals.seals}
							onRow={(record, rowIndex) => {
								return {
									onClick: () => {
										console.log("record ....", record);
										//setSealData(record);
										setStateSeals({
											...stateSeals,
											sealInfo: {
												id_seal: record.id,
												seal_type: record.type_id,
												seal_owner: record.owner_id,
												seal_status: record.status_id,
												seal_number: record.number,
											},
											sealData: record,
										});
									},
								};
							}}
						/>

						<div className="clear"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SealsTab;
