/* eslint-disable no-unused-vars */

import React, { useReducer, useState, useEffect } from "react";
import SealsContext from "../context/sealsContext";
import axios from "axios";

const SealsState = ({ children }) => {
	const [stateSeals, setStateSeals] = useState({
		sealsIDS: [],
		seals: null,
		updateState: false,
		updateStateForm: false,
		sealsName: "Souy un sello en el state",
		selectedRowKeys: [],
		selectedRows: [],
		loading: true,
		masiveApprob: false,
		actionID: null,
		sealData: null,
		revisionData: null,
		incidentData: null,
		revisionNumber: null,
		incidentNumber: null,
		sealAllData: null,
		sealInfo: {
			id_seal: null,
			seal_type: null,
			seal_owner: null,
			seal_status: null,
			seal_number: null,
		},
	});

	useEffect(() => {
		// if (_user) {
		// 	stateSeals.sealAllData = user;
		// 	setStateSeals({ ...stateSeals });
		// }
	}, []);

	const setAllData = (data) => {
		setStateSeals({ ...stateSeals, sealAllData: data });
	};

	const { selectedRowKeys, loading } = stateSeals;

	return (
		<SealsContext.Provider
			value={{
				...stateSeals,
				stateSeals,
				setStateSeals,
			}}
		>
			{children}
		</SealsContext.Provider>
	);
};

export default SealsState;
