/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import { HiOutlineTicket } from "react-icons/hi";
import { GiBattleship } from "react-icons/gi";
import { BsCheck2Circle } from "react-icons/bs";
import { BiBuilding } from "react-icons/bi";
import moment from "moment";
import { Select } from "antd";
import profile from "../../assets/images/generals/profilep.png";

import SealsState from "../../context/sealsContext";

const { Option } = Select;

function DashboardCompanies() {
	const { stateSeals, setStateSeals } = useContext(SealsState);

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};

	useEffect(() => {
		const data = async () => {};

		data();

		return () => {};
	}, []);

	return (
		<>
			<div className="companies_section text-gray">
				<div className="header_company">
					<div className="title_company text-white psc__section_title">
						Empresas
					</div>
					<div className="select_filer_companies">
						<Select
							placeholder="Filtrar por"
							style={{
								width: 160,
							}}
							onChange={handleChange}
						>
							<Option value="0">Últimos 30 días</Option>
						</Select>
					</div>
					<div className="clear"></div>
				</div>
				<div className="header_company">
					<div className="subtitle_company text-gray psc__text_normal">
						Nombre
					</div>
					<div className="subtitle_company text-gray text-right psc__text_normal mb-10">
						Número de sellos
					</div>

					<div className="clear"></div>
					<div className="line"></div>
				</div>

				<div className="company_list_Section">
					<div className="list_company">
						<div className="icon">
							<img src={profile} alt="icon" />
						</div>
						<div className="data_seals  psc__text_normal">
							<div className="company_name text-white">
								Panama Shipping Complance
							</div>
							<div className="company_seals text-sky-blue">
								{stateSeals?.sealAllData?.generate_seals} Sellos
							</div>

							<span className=""></span>
						</div>
						<div className="clear"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DashboardCompanies;
