/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from "antd";
import { BsFileEarmarkText } from "react-icons/bs";
import { Upload, message } from "antd";
import { urlService } from "../../services/UrlService";
import { Service } from "../../services/api";
import DataContext from "../../context/dataContext";
import ComponentContext from "../../context/componentContext";
import SealsContext from "../../context/sealsContext";
import Renderif from "../../components/Renderif";

function ModalCreate(props) {
	const { postData, getData } = useContext(DataContext);
	const { setStateSeals, stateSeals } = useContext(SealsContext);
	const { stateComp, setStateComp, sealModalCreate } =
		useContext(ComponentContext);

	const [state, setState] = useState({
		seals_types: [],
		seals_owners: [],
		seals_status: [],
	});

	const [idSeal, setIDSeal] = useState("");
	const [idSealError, setidSealError] = useState("");
	const [modalInfo, setModalInfo] = useState(true);
	const [oneByOneSeals, setOneByOneSeals] = useState(false);
	const [masiveSeals, setMasiveSeals] = useState(false);
	const [sealType, setSealType] = useState(1);
	const [sealOwner, setSealOwner] = useState(1);
	const [sealStatus, setSealStatus] = useState(1);

	const { Dragger } = Upload;

	useEffect(() => {
		const data = async () => {
			let _seals_types = await Service("GET", "seals/types");
			console.log("===SEALS_TYPES====", _seals_types);

			let _seals_owners = await Service("GET", "seals/owners");
			console.log("===SEALS OWNERS ====", _seals_owners);

			let _seals_status = await Service("GET", "seals/status");
			console.log("===SEALS STATUS====", _seals_status);

			setState({
				...state,
				seals_types: _seals_types.data.response,
				seals_owners: _seals_owners.data.response,
				seals_status: _seals_status.data.response,
			});

			setIDSeal("");
			setSealType("1");
			setSealOwner("1");
			setSealStatus("1");
		};
		data();
	}, [stateSeals]);

	const props_Drag = {
		name: "cvsuploaded",
		multiple: true,
		action: `${urlService}/seals/csv/upload`,

		onChange(info) {
			const { status } = info.file;

			if (status !== "uploading") {
				console.log(info.file, info.fileList);
			}

			if (status === "done") {
				message.success(
					`${info.file.name} El archivo ha subido correctamente.`
				);

				const fileURL = `${urlService}/uploads/documents/seals/${info.file.response.file}`;
				console.log("File -->", fileURL);
				readCSV(fileURL);
			} else if (status === "error") {
				message.error(`${info.file.name} la carga del archivo falló.`);
			}
		},

		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const readCSV = async (file) => {
		const csvData = {
			file,
		};

		console.log("csvData", csvData);

		let _products = await Service("POST", `seals/csv/read`, csvData);
		console.log("===PRODUCTS====", _products.data.response);
		console.log("===SEALS====", stateSeals?.seals);

		let sealsCSV = _products.data.response;

		let sealInsert = [];
		let sealsRepeat = [];
		let existsSeals = stateSeals?.seals;

		sealsCSV.map((seal) => {
			console.log("seal", seal);
			console.log("userExists", userExists(seal.number));

			if (!userExists(seal.number)) {
				let dataSeal = {
					number: seal.number,
					type: seal.type,
				};
				sealInsert.push(dataSeal);
			} else {
				sealsRepeat.push(seal.number);
			}
		});

		let _multipleSeals = await Service(
			"POST",
			`seals/insertMultipleSeals`,
			sealInsert
		);

		console.log("_multipleSeals", _multipleSeals);
		console.log("sealsRepeat", sealsRepeat);
		if (_multipleSeals.data.status === 200) {
			let messageResponse;
			if (sealsRepeat.length > 0) {
				let sealsRepeatToString = JSON.stringify(sealsRepeat);
				let sealsRepeatClear = sealsRepeatToString
					.replaceAll(",", ", ")
					.replaceAll("[", " ")
					.replaceAll("]", " ")
					.replaceAll('"', "");

				messageResponse = `Se han creado correctamente los sellos, sin embargo los siguientes sellos ya existen la base de datos: ${sealsRepeatClear}  `;
			} else {
				messageResponse = _products.data.message;
			}

			setStateComp({
				modalStatus: "success",
				modalMessageTitle: "Operación realizada con éxito:",
				modalMessageDescription: messageResponse,
				modalMessage: true,
				sealModalCreate: false,
			});
			cancelMasiveForm();

			setStateSeals({
				updateState: !stateSeals.updateState,
			});
		} else {
			setStateComp({
				modalStatus: "error",
				modalMessageTitle: "La operación ha fallado:",
				modalMessageDescription: "No se pudieron insertar los sellos",
				modalMessage: true,
				sealModalCreate: false,
			});
			cancelMasiveForm();
		}
	};

	const userExists = (number) => {
		return stateSeals?.seals.some(function (el) {
			return el.number === number;
		});
	};

	const closeModal = () => {
		setStateComp({
			sealModalCreate: false,
		});

		setIDSeal("");
		setSealType(1);
		setSealOwner(1);
		setSealStatus(1);
		setOneByOneSeals(false);
		setMasiveSeals(false);
		setModalInfo(true);
	};

	const showOneByOneForm = () => {
		setOneByOneSeals(true);
		setModalInfo(false);
	};

	const cancelOneByOneForm = () => {
		setOneByOneSeals(false);
		setModalInfo(true);
		setIDSeal("");
		setSealType(1);
		setSealOwner(1);
		setSealStatus(1);
	};

	const showMasiveForm = () => {
		setMasiveSeals(true);
		setModalInfo(false);
	};

	const cancelMasiveForm = () => {
		setMasiveSeals(false);
		setModalInfo(true);
	};

	const handleChange = (event) => {
		//setPassword(event.target.value);
		if (event.target.name === "idSeal") {
			const onlyText = event.target.value.replace(
				/[^A-Za-z0-9' ']/gi,
				""
			);
			setIDSeal(onlyText.toUpperCase());
			setidSealError("");
		}

		if (event.target.name === "sealType") {
			setSealType(event.target.value);
		}

		if (event.target.name === "sealOwner") {
			setSealOwner(event.target.value);
		}

		if (event.target.name === "sealStatus") {
			setSealStatus(event.target.value);
		}
	};

	const sealValidation = () => {
		if (!idSeal) {
			setidSealError("Introduce el ID del sello");
			return false;
		} else {
			setidSealError("");
		}
		return true;
	};

	const createSeal = (event) => {
		event.preventDefault();

		if (sealValidation()) {
			const dataSeal = {
				number: idSeal,
				type: parseInt(sealType),
				status: parseInt(sealStatus),
				ID_sealowner: parseInt(sealOwner),
			};

			console.log("dataSeal", dataSeal);

			postData("seals", dataSeal).then((result) => {
				console.log("result", result);
				let dataInfo = [];
				if (result.data) {
					dataInfo = [
						{
							message: result.data.message,
							status: result.data.status,
						},
					];
				} else {
					dataInfo = [
						{
							message: result.message,
							status: result.status,
						},
					];
				}

				if (dataInfo[0].status === 200) {
					setStateComp({
						modalStatus: "success",
						modalMessageTitle: "Operación realizada con éxito:",
						modalMessageDescription: dataInfo[0].message,
						modalMessage: true,
						sealModalCreate: false,
					});

					closeModal();
					setStateSeals({
						updateState: !stateSeals.updateState,
					});
				} else if (dataInfo[0].status === 500) {
					message.error(dataInfo[0].message);

					setStateComp({
						modalStatus: "error",
						modalMessageTitle: "La operación ha fallado:",
						modalMessageDescription: dataInfo[0].message,
						modalMessage: true,
						sealModalCreate: false,
					});
					//closeModal();
				} else {
					setStateComp({
						modalStatus: "error",
						modalMessageTitle: "La operación ha fallado:",
						modalMessageDescription:
							"Se ha presentando un error desconodio",
						modalMessage: true,
						sealModalCreate: false,
					});
				}
			});
		}
	};

	return (
		<>
			<Modal
				title="Nuevo Sello"
				visible={sealModalCreate}
				onCancel={closeModal}
				cancelButtonProps={{
					style: { display: "none" },
				}}
				okButtonProps={{
					style: { display: "none" },
				}}
			>
				{modalInfo ? (
					<>
						<div className="psc__modal_info">
							Elige la opción deseada para dar de alta los sellos.
						</div>

						<div className="psc__modal_btn_content">
							<div
								className="btn__normal btn__blue transition"
								onClick={showOneByOneForm}
							>
								Uno por uno
							</div>

							<div
								className="btn__normal btn__blue transition"
								onClick={showMasiveForm}
							>
								Carga masiva
							</div>

							<div className="clear"></div>
						</div>
					</>
				) : (
					""
				)}

				{oneByOneSeals ? (
					<>
						<div className="psc__modal_btn_content_center btn-center">
							<div className="form">
								<form
									className="psc__form_modal"
									autoComplete="off"
									onSubmit={createSeal}
								>
									<label>ID del sello</label>
									<input
										type="text"
										name="idSeal"
										placeholder="ID: 1234567890"
										value={idSeal}
										onChange={handleChange}
										maxLength={20}
									/>
									<div className="f_error">{idSealError}</div>

									<label>Seleccione el tipo de sello</label>

									<select
										name="sealType"
										onChange={handleChange}
										className="mb-20"
										value={sealType}
									>
										{state.seals_types?.map(
											(type, index) => {
												return (
													<>
														<option
															value={type.id}
															key={index}
														>
															{type.type}
														</option>
													</>
												);
											}
										)}
									</select>
									<label>Empresa</label>

									<select
										name="sealOwner"
										onChange={handleChange}
										className="mb-20"
										value={sealOwner}
									>
										{state.seals_owners?.map(
											(owner, index) => {
												return (
													<>
														<option
															value={owner.id}
															key={index}
														>
															{owner.name}
														</option>
													</>
												);
											}
										)}
									</select>

									<label>Status</label>

									<select
										name="sealStatus"
										onChange={handleChange}
										className="mb-20"
										value={sealStatus}
									>
										{state.seals_status?.map(
											(status, index) => {
												return (
													<>
														<Renderif
															isTrue={
																status.id !==
																	3 &&
																status.id !==
																	4 &&
																status.id !== 6
															} //Se ocualta en Transito, Com´pleado y eliminado
														>
															<option
																value={
																	status.id
																}
																key={index}
															>
																{status.status}
															</option>
														</Renderif>
													</>
												);
											}
										)}
									</select>
									<input
										type="submit"
										value="Crear Sello"
										className="btn__normal btn__blue transition"
									/>

									<div
										className="btn__normal btn__orange transition"
										onClick={cancelOneByOneForm}
									>
										Cancelar
									</div>
								</form>
							</div>

							<div className="clear"></div>
						</div>
					</>
				) : (
					""
				)}

				{masiveSeals ? (
					<>
						<div className="psc__modal_btn_content">
							<div className="info">
								Selecciona el archivo con los sellos a cargar.{" "}
								<br />
								<a
									href={`${urlService}/uploads/documents/base_seal.csv`}
								>
									Descarga el formato aquí
								</a>
							</div>

							<Dragger {...props_Drag} className="drag-content">
								<p className="ant-upload-drag-icon text-gray">
									<BsFileEarmarkText />
								</p>
								<p className="ant-upload-text">
									Arrastra tu archivo aquí
								</p>
							</Dragger>

							<div className="psc__modal_btn_content_center">
								<div
									className="btn__normal  btn__orange transition"
									onClick={cancelMasiveForm}
								>
									Cancelar
								</div>
							</div>

							<div className="clear"></div>
						</div>
					</>
				) : (
					""
				)}
			</Modal>
		</>
	);
}

export default ModalCreate;
