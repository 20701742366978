/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal } from "antd";
import DataContext from "../../context/dataContext";
import ComponentContext from "../../context/componentContext";
import Renderif from "../../components/Renderif";
import SealsContext from "../../context/sealsContext";
import { Service } from "../../services/api";

function UpdateSeal(props) {
	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { setStateSeals, stateSeals } = useContext(SealsContext);

	const [idSeal, setIDSeal] = useState("");
	const [sealNumber, setSealNumber] = useState("");
	const [sealType, setSealType] = useState("");
	const [sealNumberError, setSealNumberError] = useState("");
	const [sealOwner, setSealOwner] = useState("1");
	const [sealStatus, setSealStatus] = useState("1");
	const [state, setState] = useState({
		seals_types: [],
		seals_owners: [],
		seals_status: [],
	});

	useEffect(() => {
		const data = async () => {
			let _seals_types = await Service("GET", "seals/types");
			console.log("===SEALS_TYPES====", _seals_types);

			let _seals_owners = await Service("GET", "seals/owners");
			console.log("===SEALS OWNERS ====", _seals_owners);

			let _seals_status = await Service("GET", "seals/status");
			console.log("===SEALS STATUS====", _seals_status);

			setIDSeal(stateSeals.sealInfo?.id_seal);
			setSealNumber(stateSeals.sealInfo?.seal_number);
			setSealType(stateSeals.sealInfo?.seal_type);
			setSealOwner(stateSeals.sealInfo?.seal_owner);
			setSealStatus(stateSeals.sealInfo?.seal_status);

			setState({
				...state,
				seals_types: _seals_types.data.response,
				seals_owners: _seals_owners.data.response,
				seals_status: _seals_status.data.response,
			});
		};
		data();
	}, [stateSeals]);

	const closeModal = () => {
		setStateComp({
			...stateComp,
			sealModalUpdate: false,
		});
	};

	const handleChange = (event) => {
		if (event.target.name === "sealNumber") {
			const onlyText = event.target.value.replace(
				/[^A-Za-z0-9' ']/gi,
				""
			);

			setSealNumber(onlyText.toUpperCase());
			setSealNumberError("");
		}

		if (event.target.name === "sealType") {
			setSealType(event.target.value);
		}

		if (event.target.name === "sealOwner") {
			setSealOwner(event.target.value);
		}

		if (event.target.name === "sealStatus") {
			setSealStatus(event.target.value);
		}
	};

	const sealValidation = () => {
		if (!sealNumber) {
			setSealNumberError("Introduce el ID del sello");
			return false;
		} else {
			setSealNumberError("");
		}
		return true;
	};

	const updateSeal = async (event) => {
		event.preventDefault();

		if (sealValidation()) {
			const dataSeal = {
				id: stateSeals.sealInfo.id_seal,
				number: sealNumber,
				type: sealType,
				status: sealStatus,
				ID_sealowner: sealOwner,
			};

			console.log("dataSeal", dataSeal);

			let _sealsUpdate = await Service(
				"PUT",
				"seals/updateByID",
				dataSeal
			);
			console.log("===SEALS update====", _sealsUpdate);

			if (_sealsUpdate.data.status === 200) {
				setStateComp({
					...stateComp,
					sealModalUpdate: false,
					modalStatus: "success",
					modalMessageTitle: "Operación realizada con éxito:",
					modalMessageDescription: _sealsUpdate.data.message,
					modalMessage: true,
				});

				setStateSeals({
					updateState: !stateSeals.updateState,
				});
			} else {
				setStateComp({
					...stateComp,
					sealModalUpdate: false,
					modalStatus: "error",
					modalMessageTitle: "La operación no se pudo realizar:",
					modalMessageDescription:
						"No se ha podido actualizar el sello",
					modalMessage: true,
				});
			}
		}
	};

	return (
		<>
			<Modal
				title="Actualizar Sello"
				visible={stateComp.sealModalUpdate}
				onCancel={closeModal}
				okButtonProps={{ style: { display: "none" } }}
				cancelButtonProps={{ style: { display: "none" } }}
			>
				<>
					<div className="psc__modal_info">
						Completa los siguientes campos para actualizar el sello:
					</div>
				</>
				<>
					<div className="psc__modal_btn_content_center btn-center">
						<div className="form">
							<form
								className="psc__form_modal"
								autoComplete="off"
								onSubmit={updateSeal}
							>
								<label>ID del sello</label>
								<input
									type="text"
									name="sealNumber"
									placeholder="ID: 1234567890"
									value={sealNumber}
									onChange={handleChange}
									maxLength={20}
								/>
								<div className="f_error">{sealNumberError}</div>

								<label>Tipo de sello</label>
								<select
									name="sealType"
									onChange={handleChange}
									value={sealType}
								>
									{state.seals_types?.map((type, index) => {
										return (
											<>
												<option value={type.id}>
													{type.type}
												</option>
											</>
										);
									})}
								</select>

								<label>Empresa</label>
								<select
									name="sealOwner"
									onChange={handleChange}
									value={sealOwner}
								>
									{state.seals_owners?.map((owner, index) => {
										return (
											<>
												<option value={owner.id}>
													{owner.name}
												</option>
											</>
										);
									})}
								</select>

								<label>Status</label>
								<select
									name="sealStatus"
									onChange={handleChange}
									value={sealStatus}
								>
									{state.seals_status?.map(
										(status, index) => {
											return (
												<>
													<Renderif
														isTrue={
															status.id !== 3 &&
															status.id !== 4 &&
															status.id !== 6
														} //Se ocualta en Tránsito, Com´pleado y eliminado
													>
														<option
															value={status.id}
															key={index}
														>
															{status.status}
														</option>
													</Renderif>
												</>
											);
										}
									)}
								</select>

								<input
									type="submit"
									value="Actualizar Sello"
									className="btn__normal btn__blue transition"
								/>
								<div
									className="btn__normal btn__orange transition"
									onClick={closeModal}
								>
									Cancelar
								</div>
							</form>
						</div>

						<div className="clear"></div>
					</div>
				</>
			</Modal>
		</>
	);
}

export default UpdateSeal;
