/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/profile.css";
import profile from "../../assets/images/generals/profilep.png";
import { useNavigate } from "react-router-dom";
import { FiCheckCircle, FiEdit, FiCamera, FiX } from "react-icons/fi";
import DataContext from "../../context/dataContext";
import ProfileUpdateForm from "./profileUpdateForm";
import IconButton from "../../components/buttons/iconButton";
import { urlService, DashboardURL } from "../../services/UrlService";
import AuthContext from "../../context/authContext";

import moment from "moment";

function ProfileDataUpdate() {
	const navigate = useNavigate();

	const { user } = useContext(AuthContext);

	const {
		getData,
		getDataCountries,
		data,
		data_user,
		updateUserInfo,
		setUpdateUserInfo,
		setGeneralUserInfo,
		UploadProps,
		setModalUpdatePhoto,
		getDataGeneral,
		data_countries,
	} = useContext(DataContext);
	const [userInfo, setUserInfo] = useState([]);

	const changeProfilePicture = () => {};

	useEffect(() => {
		getDataGeneral(`countries/`, "get_country_types").then(() => {
			console.log("data_countries -->", data_countries);
		});

		return () => {};
	}, []);

	return (
		<>
			<div className="pcs__center_content">
				<div className="section_title text-white psc__section_title">
					Editar Perfil
				</div>
				<div className="profile_section bg-dark-blue-secondary">
					<div className="subcontent">
						<div className="section_photo">
							<div className="photo">
								<img
									src={
										urlService +
										"/images/users/" +
										user?.photo
									}
									alt="foto"
								/>
							</div>
						</div>

						<div className="section_info">
							<div className="user_info">
								<IconButton
									icon={<FiCamera />}
									className="btn__icon_type_2 mr-10  btn__purple"
									color="#9899a1"
									width="210px"
									title="Cambiar foto de perfil"
									onClick={() => setModalUpdatePhoto(true)}
								/>

								<div className="clear"></div>
							</div>

							<div className="user_data">
								<ProfileUpdateForm />
							</div>
						</div>

						<div
							className="user_edit"
							onClick={() => setUpdateUserInfo(!updateUserInfo)}
						>
							<div className="icon bg-purple">
								<FiX />
							</div>
						</div>

						<div className="clear"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ProfileDataUpdate;
