/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit, FiUnlock } from "react-icons/fi";
import { TbLock } from "react-icons/tb";
import { Space } from "antd";
import DataContext from "../../context/dataContext";
import Renderif from "../../components/Renderif";
import SealsContext from "../../context/sealsContext";
import AuthContext from "../../context/authContext";
import ComponentContext from "../../context/componentContext";

export const SealData = (propos) => {
	const { SetSealDetailModal } = useContext(DataContext);
	const { stateComp, setStateComp } = useContext(ComponentContext);

	const [filtersInfo, setFiltersInfo] = useState([
		{
			text: "B8466B73TEST2",
			value: "B8466B73TEST2",
		},
		{
			text: "B8466B73",
			value: "B8466B73",
		},
	]);
	return (
		<>
			<div
				className="pointer"
				onClick={() =>
					setStateComp({
						...stateComp,
						sealModalDetails: true,
					})
				}
			>
				{propos.name}
			</div>
		</>
	);
};

function SealsActions(props) {
	const { setSealUpdateModal } = useContext(DataContext);

	//console.log("props XXX", props);

	const { user } = useContext(AuthContext);
	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { setStateSeals, sealsIDS } = useContext(SealsContext);

	const NotPermisions = () => {
		console.log("asignar...");
		setStateComp({
			modalStatus: "403",
			modalMessageTitle: "No tienes permisos:",
			modalMessageDescription:
				"No tienes permisos para realizar esta operación",
			modalMessage: true,
			sealModalCreate: false,
		});
	};

	const ActionSeals = (type, ID, status) => {
		console.log(type, ID, status);

		//setActionID(ID);

		setStateSeals({
			actionID: ID,
		});

		if (type === "delete") {
			if (status !== 3 && status !== 4) {
				setStateComp({
					...stateComp,
					sealModalAction: true,
					actionInfo: {
						message:
							"¿Estás seguro que deseas eliminar este sello?",
						button: "Eliminar",
						status: 6,
						endpoint: "seals/deleteblock",
						endPointRefresh: "seals",
					},
				});
			} else {
				setStateComp({
					...stateComp,
					modalStatus: "403",
					modalMessageTitle: "No puedes eliminar este sello",
					modalMessageDescription: "",
					modalMessage: true,
				});
			}
		}

		if (type === "edit") {
			if (status !== 3 && status !== 4) {
				setStateComp({
					...stateComp,
					sealModalUpdate: true,
				});
			} else {
				setStateComp({
					...stateComp,
					modalStatus: "403",
					modalMessageTitle: "No puedes editar este sello",
					modalMessageDescription: "",
					modalMessage: true,
				});
			}
		}

		if (type === "block") {
			if (status !== 3 && status !== 4) {
				setStateComp({
					...stateComp,
					sealModalAction: true,
					actionInfo: {
						message:
							"¿Estás seguro que deseas bloquear este sello?",
						button: "Bloquear",
						status: 5,
						endpoint: "seals/deleteblock",
						endPointRefresh: "seals",
					},
				});
			} else {
				setStateComp({
					...stateComp,
					modalStatus: "403",
					modalMessageTitle: "No puedes bloquear este sello",
					modalMessageDescription: "",
					modalMessage: true,
				});
			}
		}

		if (type === "desblock") {
			//setModalActionSeal(true);
			setStateComp({
				...stateComp,
				sealModalAction: true,
				actionInfo: {
					message: "¿Estás seguro que deseas desbloquear este sello?",
					button: "Desbloquear",
					status: 1,
					endpoint: "seals/deleteblock",
					endPointRefresh: "seals",
				},
			});
		}

		//setModalDeleteUser(true);
	};

	const onChange = (e) => {
		console.log(`checked = ${e}`);

		setStateSeals((state) => {
			let arraySealsIDs = state.sealsIDS;

			if (arraySealsIDs.includes(e) === false) {
				console.log("ya existe");
				setStateSeals({
					sealsIDS: [...sealsIDS, Number(e)],
				});
			} else {
				console.log("NO existe");
			}

			return state.sealsIDS;
		});
	};

	return (
		<>
			{/* <Renderif isTrue={user?.rol === 1}> */}
				<Space size="middle">
					<FaTrashAlt
						className="iconA text-purple"
						onClick={() =>
							ActionSeals("delete", props.ID, props.status_id)
						}
					/>
					<FiEdit
						className="iconA text-purple"
						onClick={() =>
							ActionSeals("edit", props.ID, props.status_id)
						}
					/>

					{props.status === "Bloqueado" ? (
						<>
							<FiUnlock
								className="iconA text-purple"
								onClick={() =>
									ActionSeals(
										"desblock",
										props.ID,
										props.status_id
									)
								}
							/>
						</>
					) : (
						<>
							<TbLock
								className="iconA text-purple"
								onClick={() =>
									ActionSeals(
										"block",
										props.ID,
										props.status_id
									)
								}
							/>
						</>
					)}
				</Space>
			{/* </Renderif> */}

			{/* <Renderif isTrue={user?.rol === 2}>
				<Space size="middle">
					<FaTrashAlt
						className="iconA text-gray"
						onClick={() => NotPermisions()}
					/>
					<FiEdit
						className="iconA text-purple"
						onClick={() =>
							ActionSeals("edit", props.ID, props.status_id)
						}
					/>

					{props.status === "Bloqueado" ? (
						<>
							<FiUnlock
								className="iconA text-gray"
								onClick={() => NotPermisions()}
							/>
						</>
					) : (
						<>
							<TbLock
								className="iconA text-gray"
								onClick={() => NotPermisions()}
							/>
						</>
					)}
				</Space>
			</Renderif> */}
		</>
	);
}

export default SealsActions;

export const SealsColumns = [
	{
		title: "Número ID",
		dataIndex: "number",
		key: "number",

		sorter: (a, b) => a.number.length - b.number.length,
		filterSearch: true,
		sortDirections: ["descend", "ascend"],

		render: (id) => (
			<>
				<SealData name={id} />
			</>
		),
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		filters: [
			{
				text: "Completado",
				value: "Completado",
			},
			{
				text: "Sin asignar",
				value: "Sin asignar",
			},
			{
				text: "Asignado",
				value: "Asignado",
			},
			{
				text: "Bloqueado",
				value: "Bloqueado",
			},

			{
				text: "En tránsito",
				value: "En tránsito",
			},

			
		],
		filterMode: "tree",
		onFilter: (value, record) => record.status.startsWith(value),
		render: (status, row) => (
			<>
				<Renderif isTrue={status === "Sin asignar"}>
					<div className="s_status s_unassign">{status}</div>
				</Renderif>

				<Renderif isTrue={status === "Asignado"}>
					<div className="s_status s_assign">{status}</div>
				</Renderif>

				<Renderif isTrue={status === "Completado"}>
					<div className="s_status s_success">{status}</div>
				</Renderif>

				<Renderif isTrue={status === "Bloqueado"}>
					<div className="s_status s_block">{status}</div>
				</Renderif>

				<Renderif isTrue={status === "Eliminado"}>
					<div className="s_status s_delete">{status}</div>
				</Renderif>

				<Renderif isTrue={status === "En tránsito"}>
					<div className="s_status s_transit">{status}</div>
				</Renderif>
			</>
		),
	},

	{
		title: "Tipo de sello",
		dataIndex: "type",
		key: "type",
		filters: [
			{
				text: "Tipo 1",
				value: "Tipo 1",
			},
			{
				text: "Tipo 2",
				value: "Tipo 2",
			},
			{
				text: "Tipo 3",
				value: "Tipo 3",
			},
		],
		filterMode: "tree",
		onFilter: (value, record) => record.type.startsWith(value),
		render: (type) => (
			<>
				<Renderif isTrue={type === "Tipo 1"}>
					<div className="s_type s_type_1">{type}</div>
				</Renderif>

				<Renderif isTrue={type === "Tipo 2"}>
					<div className="s_type s_type_2">{type}</div>
				</Renderif>

				<Renderif isTrue={type === "Tipo 3"}>
					<div className="s_type s_type_3">{type}</div>
				</Renderif>
			</>
		),
	},

	{
		title: "OCR",
		dataIndex: "ocr",
		key: "ocr",
		render: (ocr) => (
			<>
				<Renderif isTrue={ocr !== null}>
					<div className="s_ocr text-orange">{ocr}</div>
				</Renderif>

				<Renderif isTrue={ocr === null}>
					<div className="">Aún no hay datos</div>
				</Renderif>
			</>
		),
	},

	{
		title: "Acciones",
		key: ["action", "id", "status", "status_id"],
		render: (text, row) => (
			<SealsActions
				ID={row.id}
				status={row.status}
				status_id={row.status_id}
			/>
		),
	},
];
