/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from "antd";
import { BsFileEarmarkText } from "react-icons/bs";
import { eye } from "react-icons-kit/icomoon/eye";
import { eyeBlocked } from "react-icons-kit/icomoon/eyeBlocked";
import { Icon } from "react-icons-kit";

import { Upload, message } from "antd";
import DataContext from "../../context/dataContext";
import { DashboardURL } from "../../services/UrlService";
import UsersContext from "../../context/usersContext";
import ComponentContext from "../../context/componentContext";
import { Service } from "../../services/api";

var sha1 = require("sha1");
function ModalCreate(props) {
	const { UserToken, passwordExpired } = useContext(DataContext);

	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { userState, setUserState } = useContext(UsersContext);
	const [icon, setIcon] = useState(eyeBlocked);
	const [type, setType] = useState("password");

	const [state, setState] = useState({
		users_rols: [],
		users_countries: [],
	});

	const [firstname, setfirstname] = useState("");
	const [firstnameError, setfirstnameError] = useState("");
	const [lastname, setlastname] = useState("");
	const [lastnameError, setlastnameError] = useState("");
	const [userPhone, setuserphone] = useState("");
	const [userPhoneError, setuserPhoneError] = useState("");
	const [userEmail, setuserEmail] = useState("");
	const [userEmailError, setuserEmailError] = useState("");
	const [password, setpassword] = useState("");
	const [rolError, setRollError] = useState("");
	const [countryError, setCountryError] = useState("");
	const [passwordError, setpasswordError] = useState("");
	const [rolType, setrolType] = useState("0");
	const [countryType, setCountryType] = useState("0");

	const closeModal = () => {
		console.log("closeModal");

		setStateComp({
			...stateComp,
			userModalCreate: false,
		});

		setfirstname("");
		setlastname("");
		setuserphone("");
		setuserEmail("");
		setpassword("");
		setrolType("1");
		setCountryType("1");
	};

	const handleChange = (event) => {
		if (event.target.name === "firstname") {
			const onlyText = event.target.value.replace(/[^a-zA-Z' ']/gi, "");
			setfirstname(onlyText);
			setfirstnameError("");
		}

		if (event.target.name === "lastname") {
			const onlyText = event.target.value.replace(/[^a-zA-Z' ']/gi, "");

			setlastname(onlyText);
			setlastnameError("");
		}

		if (event.target.name === "userphone") {
			const onlyNumbers = event.target.value.replace(/\D/g, "");
			setuserphone(onlyNumbers);
			setuserPhoneError("");
		}

		if (event.target.name === "password") {
			setpassword(event.target.value);
			setpasswordError("");
		}

		if (event.target.name === "userEmail") {
			setuserEmail(event.target.value);
			setuserEmailError("");
		}

		if (event.target.name === "rolType") {
			setrolType(event.target.value);
		}

		if (event.target.name === "countryType") {
			setCountryType(event.target.value);
		}
	};

	const emailValidation = () => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!userEmail || regex.test(userEmail) === false) {
			console.log("El correo no es válido");
			setuserEmailError("Introduce un correo válido");
			return false;
		} else {
			setuserEmailError("");
		}

		return true;
	};

	const userValidation = () => {
		if (!firstname) {
			setfirstnameError("Introduce el nombre del usuario");
			return false;
		} else {
			setfirstnameError("");
		}

		if (!lastname) {
			setlastnameError("Introduce el apellido del usuario");
			return false;
		} else {
			setlastnameError("");
		}
		if (!userPhone) {
			setuserPhoneError("Introduce el teléfono del usuario");
			return false;
		} else {
			setuserPhoneError("");
		}

		if (!userEmail) {
			setuserEmailError("Introduce el correo del usuario");
			return false;
		} else {
			emailValidation();
		}

		if (!password) {
			setpasswordError("Introduce la contraseña del usuario");
			return false;
		} else {
			setpasswordError("");
		}

		if (rolType === "0") {
			setRollError("Selecciona el Rol de usuario");
			return false;
		} else {
			setRollError("");
		}

		if (countryType === "0") {
			setCountryError("Selecciona el País del usuario");
			return false;
		} else {
			setCountryError("");
		}

		return true;
	};

	const createUser = async (event) => {
		event.preventDefault();
		if (userValidation() && emailValidation()) {
			const userData = {
				firstname: firstname,
				lastname: lastname,
				email: userEmail,
				phone: userPhone,
				rol: parseInt(rolType),
				status: 4,
				ID_country: parseInt(countryType),
				token: UserToken(),
				passwordUpdate: passwordExpired(),
				password: sha1(password),
			};

			console.log("userData", userData);

			let _createUses = await Service("POST", "users", userData);

			if (_createUses.data.status === 200) {
				setStateComp({
					...stateComp,
					modalStatus: "success",
					modalMessageTitle: "Operación realizada con éxito:",
					modalMessageDescription:
						"El usuario se ha creado con exito",
					modalMessage: true,
					userModalCreate: false,
				});

				let userEmailData = {
					email: _createUses.data.response[0].email,
					username:
						_createUses.data.response[0].firstname +
						" " +
						_createUses.data.response[0].lastname,
					token: _createUses.data.response[0].token,
					siteURL:
						DashboardURL +
						"/user/verify/?token=" +
						_createUses.data.response[0].token,
				};

				console.log("userEmailData", userEmailData);

				let _sendEmail = await Service(
					"POST",
					"email/user/verify/",
					userEmailData
				);

				setfirstname("");
				setlastname("");
				setuserphone("");
				setuserEmail("");
				setpassword("");
				setrolType("1");
				setCountryType("1");

				//console.log("_sendEmail@", _sendEmail);
			}

			if (_createUses.data.status === 500) {
				setStateComp({
					...stateComp,
					modalStatus: "error",
					modalMessageTitle: "No se pudo crear el usuario:",
					modalMessageDescription: _createUses.data.message,
					modalMessage: true,
					userModalCreate: false,
				});
			}

			console.log("_CreateUses", _createUses);

			setUserState({
				updateState: !userState.updateState,
			});
		} else {
			console.log("NO SE VALIDO", emailValidation());
		}
	};

	const showPassword = () => {
		if (type === "password") {
			setIcon(eye);
			setType("text");
		} else {
			setIcon(eyeBlocked);
			setType("password");
		}
	};

	useEffect(() => {
		const data = async () => {
			let _rols = await Service("GET", "rols");
			console.log("===ROLS====", _rols);

			let _countries = await Service("GET", "countries");
			console.log("===COUNTRIES====", _countries);

			setState({
				...state,
				rols: _rols.data.response,
				countries: _countries.data.response,
			});

			setrolType("1");
			setCountryType("1");
		};
		data();
	}, [userState.updateState]);
	return (
		<>
			<Modal
				title="Nuevo Usuario"
				visible={stateComp.userModalCreate}
				onCancel={() => closeModal()}
				okButtonProps={{ style: { display: "none" } }}
				cancelButtonProps={{ style: { display: "none" } }}
			>
				<>
					<div className="psc__modal_btn_content_center btn-center">
						<div className="form">
							<form
								className="psc__form_modal"
								autoComplete="off"
								onSubmit={createUser}
							>
								<div className="PSC__form_50">
									<div className="form_content">
										<label>Nombre(s)</label>
										<input
											type="text"
											name="firstname"
											placeholder=""
											value={firstname}
											maxLength={20}
											onChange={handleChange}
										/>
										<div className="f_error">
											{firstnameError}
										</div>

										<label>Correo Electrónico</label>
										<input
											type="text"
											name="userEmail"
											placeholder=""
											value={userEmail}
											onChange={handleChange}
										/>
										<div className="f_error">
											{userEmailError}
										</div>

										<label>Seleccione el rol</label>

										<select
											name="rolType"
											onChange={handleChange}
											className="mb-20"
											value={rolType}
										>
											{state?.rols?.map((rol, index) => {
												return (
													<>
														<option
															value={rol.id}
															key={index}
														>
															{rol.name}
														</option>
													</>
												);
											})}
										</select>

										<div className="f_error">
											{rolError}
										</div>

										<label>País</label>
										<select
											name="countryType"
											onChange={handleChange}
											className="mb-20"
										>
											{state?.countries?.map(
												(country, index) => {
													return (
														<>
															<option
																value={
																	country.id
																}
																key={index}
															>
																{country.name}
															</option>
														</>
													);
												}
											)}
										</select>
										<div className="f_error mb-20">
											{countryError}
										</div>

										<div className="clear"></div>
									</div>
								</div>

								<div className="PSC__form_50">
									<div className="form_content">
										<label>Apellido(s)</label>
										<input
											type="text"
											name="lastname"
											placeholder=""
											value={lastname}
											onChange={handleChange}
											maxLength={30}
										/>
										<div className="f_error">
											{lastnameError}
										</div>

										<label>Número de teléfono</label>
										<input
											type="text"
											name="userphone"
											placeholder=""
											value={userPhone}
											onChange={handleChange}
											maxLength={10}
										/>
										<div className="f_error">
											{userPhoneError}
										</div>

										<label>Asignar contraseña</label>

										<div className="passwordInput">
											<div className="eye">
												<span onClick={showPassword}>
													<Icon
														icon={icon}
														size="22"
													/>
												</span>
											</div>
											<input
												type={type}
												name="password"
												placeholder=""
												value={password}
												onChange={handleChange}
											/>
										</div>
										<div className="f_error ">
											{passwordError}
										</div>

										<div className="clear"></div>
									</div>
								</div>
								<div className="clear"></div>

								<input
									type="submit"
									value="Crear usuario"
									className="btn__normal btn__blue transition"
								/>
							</form>
						</div>

						<div className="clear"></div>
					</div>
				</>
			</Modal>
		</>
	);
}

export default ModalCreate;
