/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import "../../assets/css/seals.css";
import { BiSearch, BiBell } from "react-icons/bi";
import profile from "../../assets/images/generals/profilep.png";
import DataContext from "../../context/dataContext";
import SealsTab from "./SealsTab";
import SearchForm from "../../components/SearchForm";
import HeaderDashboard from "../../components/HeaderDashboard";
import ModalCreate from "./ModalCreate";
import ModalReport from "../../components/modals/report";
import SealsAssign from "./sealsAssign";
import UpdateSeals from "./UpdateSeals";
import ModalBlockDelete from "./ModalBlockDelete";
import SealsDetails from "./SealsDetails";
import RevisionsDetails from "./RevisionDetails";
import IncidentsDetails from "./IndicentsDetails";

function Seals() {
	const { data, getData, setUptadeState, updateState, text, setText } =
		useContext(DataContext);
	const [initData, setInitData] = useState(false);
	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		console.log("se actualizo el estado");
	}, [updateState]);

	return (
		<>
			<HeaderDashboard />
			<SealsTab />
			<ModalCreate />
			<ModalReport/>
			<ModalBlockDelete />
			<SealsAssign />
			<UpdateSeals />
			<SealsDetails />
			<RevisionsDetails />
			<IncidentsDetails />
		</>
	);
}

export default Seals;
