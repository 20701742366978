/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal } from "antd";
import { message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import DataContext from "../../context/dataContext";
import { urlService } from "../../services/UrlService";
import ImgCrop from "antd-img-crop";
import AuthContext from "../../context/authContext";
import { Service } from "../../services/api";
import ComponentContext from "../../context/componentContext";

var sha1 = require("sha1");
function ModalUpdatePhoto(props) {
	const {
		modalUpdatePhoto,
		setModalUpdatePhoto,
		putData,
		generalUserInfo,
		getData,
		getDataGeneral,
		setGeneralUserInfo,
		data_user,
	} = useContext(DataContext);

	const { setStateComp, sealModalCreate } = useContext(ComponentContext);

	const { user, setUser } = useContext(AuthContext);

	const [fileList, setFileList] = useState(0);

	const closeModal = () => {
		setModalUpdatePhoto(false);
	};

	const UploadProps = {
		name: "uploadedImage",
		multiple: false,
		listType: "picture-card",
		action: `${urlService}/users/photo/upload`,
		onChange(info) {
			setFileList(0);
			console.log("info...", info);

			const { status } = info.file;
			//const { imageName } = info.file.response.file;
			if (status !== "uploading") {
				console.log(info.file, info.fileList);
			}
			if (status === "done") {
				const image = info.file.response.file;

				setFileList(1);
				let ImageInfo = {
					email: user?.email,
					photo: image,
				};

				console.log("ImageInfo", ImageInfo);

				updateProfilePhoto(image);
			} else if (status === "error") {
				message.error(
					`${info.file.name} ha presentado un fallo al subirse.`
				);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const { Dragger } = Upload;

	const updateProfilePhoto = async (image) => {
		let ImageInfo = {
			email: user?.email,
			photo: image,
		};

		let _update = await Service("PUT", `users/update/photo`, ImageInfo);

		if (_update.data.error === false) {
			setStateComp({
				modalStatus: "success",
				modalMessageTitle: "Operación realizada con éxito:",
				modalMessageDescription:
					"Se ha actualizado correctamente la foto",
				modalMessage: true,
				sealModalCreate: false,
			});

			let _userInfo = await Service("GET", `users/email/${user?.email}`);

			console.log("_userInfo", _userInfo);

			if (_userInfo.data.status === 200) {
				console.log("_userInfo", _userInfo.data);
				localStorage.setItem("user", JSON.stringify(_userInfo.data));
				setUser(_userInfo.data);
			}

			setModalUpdatePhoto(false);
		} else {
			setStateComp({
				modalStatus: "error",
				modalMessageTitle: "La operación ha fallado:",
				modalMessageDescription:
					"Se presento un error al actualizar los datos",
				modalMessage: true,
				sealModalCreate: false,
			});
		}

		console.log("_update photo", _update);
	};

	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			<div className="and-modal-upload">
				<Modal
					title="Actualizar foto de perfil"
					visible={modalUpdatePhoto}
					onCancel={closeModal}
					cancelButtonProps={{ style: { display: "none" } }}
					okButtonProps={{ style: { display: "none" } }}
				>
					<>
						{/* <Dragger {...UploadProps}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">
							Haz Click o arrastra la foto al recuadro de subida.
						</p>
						<p className="ant-upload-hint">
							La foto debe de ser formato cuadrada
						</p>
					</Dragger> */}

						<ImgCrop rotate>
							<Upload {...UploadProps}>
								{fileList < 1 && "+ Subir foto"}
							</Upload>
						</ImgCrop>
					</>
				</Modal>
			</div>
		</>
	);
}

export default ModalUpdatePhoto;
