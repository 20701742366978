/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import { Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { Image } from "antd";
import { urlFiles, urlService } from "../../services/UrlService";
import { MdLocationPin, MdOutlineCalendarToday } from "react-icons/md";
import "../../assets/css/sealsDetails.css";
import DataContext from "../../context/dataContext";
import { Service } from "../../services/api";
import ComponentContext from "../../context/componentContext";
import SealsContext from "../../context/sealsContext";
import Renderif from "../../components/Renderif";
import moment from "moment";

function RevisionDetails() {
	const { setModalIncidents, revisionDetail } = useContext(DataContext);
	const [revisionPictures, setRevisionPictures] = useState([]);
	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { setStateSeals, stateSeals } = useContext(SealsContext);

	const [state, setState] = useState({
		incidensts: [],
	});

	useEffect(() => {
		const data = async () => {
			console.log("Revision id", stateSeals?.revisionData?.id);
			let _incidents = await Service(
				"GET",
				`incidents/revision/${stateSeals?.revisionData?.id}`
			);
			console.log("=== INCIDENTS====", _incidents);

			if (_incidents.data.status === 200) {
				setState({ incidensts: _incidents.data.response });
			} else {
				setState({ incidensts: [] });
			}

			if (stateSeals?.revisionData?.pictures) {
				setRevisionPictures(
					JSON.parse(stateSeals?.revisionData?.pictures)
				);
			} else {
				setRevisionPictures([]);
			}
		};
		data();
	}, [!stateComp?.revisionsModalDetails]);

	const closeModal = () => {
		setStateComp({
			...stateComp,
			revisionsModalDetails: false,
			sealModalDetails: true,
		});
	};

	const showIncident = (incident, number) => {
		console.log("incident ***", incident);
		setStateComp({
			...stateComp,
			incidentsModalDetails: true,
			revisionsModalDetails: false,
		});

		setStateSeals({
			...stateSeals,
			incidentNumber: number,
			incidentData: incident,
		});
	};

	return (
		<>
			<Modal
				title=""
				centered
				visible={stateComp.revisionsModalDetails}
				onCancel={closeModal}
				width={1000}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
			>
				<div className="section_seals_details bg-white text-black">
					<div className="seal_id">
						<div className="title psc__section_subtitle_big">
							Revisión #{stateSeals?.revisionNumber}
						</div>
					</div>

					<div className="status_Data">
						<div className="title psc__section_subtitle ">
							<div className="iconSection bg-sky-blue text-white text-center">
								<MdLocationPin />
							</div>
							<span className="psc__text_normal t_span_text">
								Ubicación: {stateSeals?.revisionData?.location}
							</span>
							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle">
							<div className="iconSection bg-orange text-white text-center">
								<MdOutlineCalendarToday />
							</div>
							<span className="psc__text_normal t_span_text">
								Fecha:{" "}
								{moment(stateSeals?.revisionData?.create_date)
									.utc()
									.format("DD/MM/YYYY HH:mm A")}
							</span>
							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle title_last">
							<div className="iconSection bg-red  text-white text-center">
								<FaUser />
							</div>
							<span className="psc__text_normal t_span_text">
								Capturista: {stateSeals?.revisionData?.user}
							</span>
							<div className="clear"></div>
						</div>

						<div className="clear"></div>
					</div>

					<div className="captures_container">
						<div className="title psc__section_subtitle">
							Capturas:
						</div>

						<Renderif
							isTrue={stateSeals?.revisionData?.pictures === null}
						>
							<div className="revisions_container">
								No hay capturas para esta revision
							</div>
						</Renderif>

						<Renderif
							isTrue={stateSeals?.revisionData?.pictures !== null}
						>
							<div className="revisions_container mb-20">
								<Image.PreviewGroup>
									{revisionPictures?.map((picture, index) => {
										return (
											<>
												<Image
													width={120}
													src={`
												${urlService}/uploads/revisions/${picture.name}
												`}
												/>
											</>
										);
									})}
								</Image.PreviewGroup>
							</div>
						</Renderif>
					</div>

					<Renderif isTrue={state.incidensts.length === 0}>
						<div className="title psc__section_subtitle">
							Incidencias:
						</div>
						No hay incidencias para esta revisión
					</Renderif>

					<Renderif isTrue={state.incidensts.length > 0}>
						<div className="revisions_container noScroll">
							<div className="title psc__section_subtitle mb-10">
								Reporte de incidencias:
							</div>

							{state.incidensts?.map((incident, index) => {
								return (
									<>
										<div
											className="revison_block"
											onClick={() =>
												showIncident(
													incident,
													index + 1
												)
											}
										>
											<div className="info_b">
												<div className="title_revision_no psc__section_subtitle text-white">
													<CgFileDocument className="text-purple" />{" "}
													Incidencia #{index + 1}:{" "}
													<Renderif
														isTrue={
															incident?.type === 1
														}
													>
														Sello Roto
													</Renderif>
													<Renderif
														isTrue={
															incident?.type === 2
														}
													>
														No tiene Sello
													</Renderif>
													<Renderif
														isTrue={
															incident?.type === 3
														}
													>
														Sello no corresponde
													</Renderif>
													<Renderif
														isTrue={
															incident?.type === 4
														}
													>
														No corresponde el OCR
													</Renderif>
													<Renderif
														isTrue={
															incident?.type === 5
														}
													>
														Daño del contenedor
													</Renderif>
													<Renderif
														isTrue={
															incident?.type === 6
														}
													>
														Combo - 20 pies con
														puertas no accesibles
													</Renderif>
												</div>
											</div>
											<div className="clear"></div>
										</div>
									</>
								);
							})}
						</div>
					</Renderif>
				</div>
			</Modal>
		</>
	);
}

export default RevisionDetails;
