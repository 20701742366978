/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from "react";
import "../../assets/css/dashboard.css";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderDashboard from "../../components/HeaderDashboard";
import DashboardInfoBlocks from "./DashboardInfoBlocks";
import DashboardCompanies from "./DashboardCompanies";
import DashboardGraphs from "./DashboardGraphs";
import infoContext from "../../context/infoContext";
import { Service } from "../../services/api";

function Dashboard() {
	const { info, setInfo } = useContext(infoContext);

	useEffect(() => {
		const data = async () => {
			let _dataInfo = await Service("GET", `seals`);
			console.log("_dataInfo", _dataInfo.data.response);

			localStorage.setItem(
				"infoSeals",
				JSON.stringify(_dataInfo.data.response)
			);
			setInfo(_dataInfo.data.response);
		};

		data();

		return () => {};
	}, []);

	return (
		<>
			<HeaderDashboard />
			<div className="pcs__center_content">
				<div className="section_title text-white psc__section_title">
					Dashboard
				</div>
				<DashboardInfoBlocks />

				<div className="tables_graphs_section">
					<DashboardCompanies />
					<DashboardGraphs />

					<div className="clear"></div>
				</div>
			</div>
		</>
	);
}

export default Dashboard;
