/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/profile.css";
import { Modal, Result, Button } from "antd";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

import DataContext from "../../context/dataContext";

import UpdatePaswordModalRecovery from "../../components/modals/login/UpdatePaswordModalRecovery";

function UserVerify() {
	const search = useLocation().search;
	const tokenID = new URLSearchParams(search).get("token");
	const navigate = useNavigate();

	const { putData } = useContext(DataContext);

	useEffect(() => {
		const userData = {
			token: tokenID,
		};

		console.log(userData);

		putData("users/verify/account", userData).then((result) => {
			console.log(result);
		});
	}, []);

	const goToDashboard = () => {
		navigate("/");
	};

	return (
		<>
			<Modal
				visible={true}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
			>
				<>
					<Result
						status="success"
						title="Usuario verificado"
						subTitle="Tu usuario ha sido verificado, ahora puedes ingresar al sitio o usar la aplicación con tu usuario y contraseña"
						extra={[
							<Button
								type="primary"
								key="console"
								onClick={() => goToDashboard()}
							>
								Ir al Dashboard
							</Button>,

							<Button type="primary" key="console">
								Descargar App
							</Button>,
						]}
					/>
				</>
			</Modal>
		</>
	);
}

export default UserVerify;
