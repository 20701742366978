/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from "antd";
import { BsFileEarmarkText } from "react-icons/bs";
import { Upload, message } from "antd";
import DataContext from "../../context/dataContext";
import UsersContext from "../../context/usersContext";
import ComponentContext from "../../context/componentContext";

import { DashboardURL } from "../../services/UrlService";
import { Service } from "../../services/api";

var sha1 = require("sha1");
function UpdateUser(props) {
	const {
		userCreateModal,
		SetUserCreateModal,
		SetModalMessage,
		postData,
		getData,
		getDataCountries,
		data_countries,
		data_rols,
		UserToken,
		passwordExpired,
		updateUser,
		serUptdateUser,
		userData,
		putData,
	} = useContext(DataContext);

	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { userState, setUserState } = useContext(UsersContext);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [firstname, setfirstname] = useState("");
	const [firstnameError, setfirstnameError] = useState("");
	const [lastname, setlastname] = useState("");
	const [lastnameError, setlastnameError] = useState("");
	const [userPhone, setuserphone] = useState("");
	const [userPhoneError, setuserPhoneError] = useState("");
	const [userEmail, setuserEmail] = useState("");
	const [userEmailError, setuserEmailError] = useState("");
	const [password, setpassword] = useState("");
	const [rolError, setRollError] = useState("");
	const [countryError, setCountryError] = useState("");
	const [passwordError, setpasswordError] = useState("");
	const [rolType, setrolType] = useState("0");
	const [countryType, setCountryType] = useState("0");

	useEffect(() => {
		let data = () => {
			let user = userState?.userData;

			console.log("user", user);
			setfirstname(user?.firstname);
			setlastname(user?.lastname);
			setuserphone(user?.phone);
			setuserEmail(user?.email);
			setrolType(user?.rol_id);
			setCountryType(user?.id_country);
		};
		data();
	}, [userState]);

	const closeModal = () => {
		setStateComp({
			...stateComp,
			userModalUpdate: false,
		});
	};

	const handleChange = (event) => {
		if (event.target.name === "firstname") {
			const onlyText = event.target.value.replace(/[^a-zA-Z' ']/gi, "");
			setfirstname(onlyText);
			setfirstnameError("");
		}

		if (event.target.name === "lastname") {
			const onlyText = event.target.value.replace(/[^a-zA-Z' ']/gi, "");
			setlastname(onlyText);
			setlastnameError("");
		}

		if (event.target.name === "userphone") {
			const onlyNumbers = event.target.value.replace(/\D/g, "");
			setuserphone(onlyNumbers);
			setuserPhoneError("");
		}

		if (event.target.name === "userEmail") {
			setuserEmail(event.target.value);
			setuserEmailError("");
		}

		if (event.target.name === "rolType") {
			setrolType(event.target.value);
		}

		if (event.target.name === "countryType") {
			setCountryType(event.target.value);
		}
	};

	const emailValidation = () => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!userEmail || regex.test(userEmail) === false) {
			console.log("El correo no es válido");
			setuserEmailError("Introduce un correo válido");
			return false;
		} else {
			setuserEmailError("");
		}

		return true;
	};

	const userValidation = () => {
		if (!firstname) {
			setfirstnameError("Introduce el nombre del usuario");
			return false;
		} else {
			setfirstnameError("");
		}

		if (!lastname) {
			setlastnameError("Introduce el apellido del usuario");
			return false;
		} else {
			setlastnameError("");
		}
		if (!userPhone) {
			setuserPhoneError("Introduce el teléfono del usuario");
			return false;
		} else {
			setuserPhoneError("");
		}

		if (!userEmail) {
			setuserEmailError("Introduce el correo del usuario");
			return false;
		} else {
			emailValidation();
		}

		if (rolType === "0") {
			setRollError("Selecciona el Rol de usuario");
			return false;
		} else {
			setRollError("");
		}

		if (countryType === "0") {
			setCountryError("Selecciona el País del usuario");
			return false;
		} else {
			setCountryError("");
		}

		return true;
	};

	const createUser = async (event) => {
		event.preventDefault();
		if (userValidation() && emailValidation()) {
			console.log("userState.userData", userState?.userData);

			let user = userState?.userData;
			const userInfo = {
				id: user?.id,
				firstname: firstname,
				lastname: lastname,
				email: userEmail,
				phone: userPhone,
				rol: parseInt(rolType),
				ID_country: parseInt(countryType),
			};

			console.log("userInfo ***", userInfo);

			let _updateUSer = await Service(
				"PUT",
				"users/updateByID",
				userInfo
			);

			if (_updateUSer.data.response.status === 200) {
				setStateComp({
					...stateComp,
					modalStatus: "success",
					modalMessageTitle: "Operación realizada con éxito:",
					modalMessageDescription:
						"El usuario se ha actualizado con exito",
					modalMessage: true,
					userModalUpdate: false,
				});

				setUserState({
					...userState,
					updateState: !userState.updateState,
				});
			} else {
				setStateComp({
					...stateComp,
					modalStatus: "error",
					modalMessageTitle: "No se pudo actualzar el usuario:",
					modalMessageDescription:
						"Revisa que los datos sean correctos o no estén registrados",
					modalMessage: true,
					userModalUpdate: false,
				});
			}

			console.log("_updateUSer", _updateUSer);
		}
	};

	return (
		<>
			<Modal
				title="Editar Usuario"
				visible={stateComp.userModalUpdate}
				onCancel={closeModal}
				okButtonProps={{ style: { display: "none" } }}
				cancelButtonProps={{ style: { display: "none" } }}
			>
				<>
					<div className="psc__modal_btn_content_center btn-center">
						<div className="form">
							<form
								className="psc__form_modal"
								autoComplete="off"
								onSubmit={createUser}
							>
								<div className="PSC__form_50">
									<div className="form_content">
										<label>Nombre(s)</label>
										<input
											type="text"
											name="firstname"
											placeholder=""
											value={firstname}
											onChange={handleChange}
											maxLength={20}
										/>
										<div className="f_error">
											{firstnameError}
										</div>

										<label>Correo Electrónico</label>
										<input
											type="text"
											name="userEmail"
											placeholder=""
											value={userEmail}
											onChange={handleChange}
										/>
										<div className="f_error">
											{userEmailError}
										</div>

										<label>Seleccione el rol</label>

										<select
											name="rolType"
											onChange={handleChange}
											className="mb-20"
											value={rolType}
										>
											<option value="0">
												Sin Asignar
											</option>

											{data_rols?.map((rol, index) => {
												return (
													<>
														<option
															value={rol.id}
															key={index}
														>
															{rol.name}
														</option>
													</>
												);
											})}
										</select>

										<div className="f_error">
											{rolError}
										</div>

										<div className="clear"></div>
									</div>
								</div>

								<div className="PSC__form_50">
									<div className="form_content">
										<label>Apellido(s)</label>
										<input
											type="text"
											name="lastname"
											placeholder=""
											value={lastname}
											onChange={handleChange}
											maxLength={30}
										/>
										<div className="f_error">
											{lastnameError}
										</div>

										<label>Número de teléfono</label>
										<input
											type="text"
											name="userphone"
											placeholder=""
											value={userPhone}
											onChange={handleChange}
											maxLength={10}
										/>
										<div className="f_error">
											{userPhoneError}
										</div>

										<label>País</label>
										<select
											name="countryType"
											onChange={handleChange}
											className="mb-20"
											value={countryType}
										>
											{data_countries?.map(
												(country, index) => {
													return (
														<>
															<option
																value={
																	country.id
																}
																key={index}
															>
																{country.name}
															</option>
														</>
													);
												}
											)}
										</select>
										<div className="f_error mb-20">
											{countryError}
										</div>

										<div className="clear"></div>
									</div>
								</div>
								<div className="clear"></div>

								<input
									type="submit"
									value="Actualizar usuario"
									className="btn__normal btn__blue transition"
								/>
							</form>
						</div>

						<div className="clear"></div>
					</div>
				</>
			</Modal>
		</>
	);
}

export default UpdateUser;
