export const TYPES = {
	GET_DATA: "GET_DATA",
	POST_DATA: "POST_DATA",
	PUT_DATA: "PUT_DATA",
	GET_REVISION_BY_SEAL_ID: "GET_REVISION_BY_SEAL_ID",
	GET_DATA_COUNTRIES: "GET_DATA_COUNTRIES",
	GET_DATA_ROLS: "GET_DATA_ROLS",
	GET_DATA_INCIDENTS: "GET_DATA_INCIDENTS",
	GET_DATA_SEALS_TYPES: "GET_DATA_SEALS_TYPES",
	GET_DATA_SEALS_OWNERS: "GET_DATA_SEALS_OWNERS",
	GET_DATA_SEALS_STATUS: "GET_DATA_SEALS_STATUS",
	GET_DATA_USER: "GET_DATA_USER",
};
