/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import "../../assets/css/seals.css";
import { IoMdAdd } from "react-icons/io";
import { Table } from "antd";
import { UsersColumns } from "../../utilities/tabs/users";
import { Service } from "../../services/api";
import DataContext from "../../context/dataContext";
import UsersContext from "../../context/usersContext";
import ComponentContext from "../../context/componentContext";
import SearchForm from "../../components/SearchForm";
import ModalCreate from "./ModalCreate";

function SealsTab() {
	const {
		data,
		getData,
		SetUserCreateModal,
		sealCreateModal,
		userData,
		setUserData,
	} = useContext(DataContext);

	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { userState, setUserState } = useContext(UsersContext);

	const [state, setState] = useState({ users: [] });

	useEffect(() => {
		const data = async () => {
			let _users = await Service("GET", "users");
			console.log("===USERS ====", _users);

			let userData = _users.data.response;
			userData.map((k) => {
				k["key"] = k.id;
				return true;
			});

			setUserState({
				...userState,
				users: userData,
			});

			// setState({
			// 	...state,
			// 	users: userData,
			// });
		};

		data();
	}, [userState.updateState]);

	useEffect(() => {}, [userState.updateStateForm]);

	return (
		<>
			<div className="pcs__center_content">
				<div className="section_title text-white psc__section_title">
					Usuarios
				</div>

				<div
					className="btn__normal_add transition btn__white mb-20"
					onClick={() =>
						setStateComp({
							...stateComp,
							userModalCreate: true,
						})
					}
				>
					<div className="iconAdd text-white">
						<IoMdAdd />
					</div>{" "}
					Nuevo usuario
				</div>

				<div className="section_seals_table">
					<div className="data_tables bg-dark-blue-secondary">
						<div className="header">
							<div className="title psc__section_subtitle text-white">
								Lista de usuarios
							</div>
							{/* <div className="filter">Filtrar por</div> */}
							<div className="clear"></div>
						</div>

						<div className="header">
							<div className="search_form_block">
								<SearchForm
									width="100%"
									height="40px"
									placeholder="Buscar usuario"
									color="#9899a1"
									background="#212332"
									borderColor="#212332"
									endpoint="users"
								/>
							</div>

							<div className="clear"></div>
						</div>

						<Table
							columns={UsersColumns}
							dataSource={userState.users}
							onRow={(record, rowIndex) => {
								return {
									onClick: () => {
										console.log(record);

										setUserState({
											...userState,
											userData: record,
										});
									},
								};
							}}
						/>

						<div className="clear"></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SealsTab;
