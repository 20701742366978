/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import { Modal } from "antd";
import "../../assets/css/sealsDetails.css";
import { RiShipLine } from "react-icons/ri";
import { ImQrcode } from "react-icons/im";
import { IoMdCheckmarkCircleOutline, IoIosPaper } from "react-icons/io";
import { MdOutlineWork } from "react-icons/md";
import { Service } from "../../services/api";
import DataContext from "../../context/dataContext";
import ComponentContext from "../../context/componentContext";
import SealsContext from "../../context/sealsContext";
import Renderif from "../../components/Renderif";

function SealsDetails() {
	const { setRevisionsDetail } = useContext(DataContext);
	const { SetRevisionDetailModal, revisionData } = useContext(DataContext);
	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { setStateSeals, stateSeals } = useContext(SealsContext);
	const [state, setState] = useState({
		revisions: [],
		incidensts: [],
		revisionsFinal: [],
	});

	let existArr = [];

	useEffect(() => {
		const data = async () => {
			console.log("sealData?.id", stateSeals.sealData?.id);
			let _revisions = await Service(
				"GET",
				`revisions/seals/${stateSeals.sealData?.id}`
			);
			console.log("=== REVISIONS====", _revisions);

			if (_revisions.data.status === 200) {
				setState({ revisions: _revisions.data.response });

				_revisions.data.response?.map((seal, index) => {
					if (!sealExists(seal.id)) {
						existArr.push(_revisions.data.response[index]);
					}
				});

				setState({ revisions: existArr });
			} else {
				setState({ revisions: [] });
			}
		};
		data();
	}, [!stateComp?.sealModalDetails]);

	const sealExists = (seal_id) => {
		return existArr.some(function (el) {
			return el.id === seal_id;
		});
	};

	const closeModal = () => {
		//setIncidentData("");

		setStateComp({
			...stateComp,
			sealModalDetails: false,
		});
	};

	const DisplayRevisionModal = (data, n_revision) => {
		console.log("DisplayRevisionModal data ->", data);
		SetRevisionDetailModal(true);
		setRevisionsDetail(data);
		console.log("setRevisionsDetail", data);
	};

	const showRevision = (revision, number) => {
		console.log("revision", revision);
		setStateComp({
			...stateComp,
			revisionsModalDetails: true,
			sealModalDetails: false,
		});

		setStateSeals({
			...stateSeals,
			revisionNumber: number,
			revisionData: revision,
		});
	};

	return (
		<>
			<Modal
				title=""
				centered
				visible={stateComp.sealModalDetails}
				onCancel={closeModal}
				width={1000}
				okButtonProps={{ style: { display: "none" } }}
				cancelButtonProps={{ style: { display: "none" } }}
			>
				<div className="section_seals_details bg-white text-black">
					{/* <div className="btn__back text-white mb-30">Regresar</div> */}

					<div className="seal_id">
						<div className="title psc__section_subtitle_big">
							Detalle Sello ID: {stateSeals.sealData?.number}
						</div>
					</div>

					<div className="status_Data">
						<div className="title psc__section_subtitle">
							<div className="iconSection bg-sky-blue text-white text-center">
								<RiShipLine />
							</div>
							<span className="psc__text_normal t_span_text">
								{stateSeals.sealData?.status}
							</span>
							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle">
							<div className="iconSection bg-green-bull text-white text-center">
								<IoMdCheckmarkCircleOutline />
							</div>
							<Renderif isTrue={state.revisions?.length > 0}>
								<span className="psc__text_normal t_span_text">
									Número de revisiones:{" "}
									{state.revisions?.length}
								</span>
							</Renderif>

							<Renderif isTrue={state.revisions?.length === 0}>
								<span className="psc__text_normal t_span_text">
									Sin revisiones
								</span>
							</Renderif>

							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle ">
							<div className="iconSection bg-purple  text-white text-center">
								<MdOutlineWork />
							</div>
							<span className="psc__text_normal t_span_text">
								{stateSeals.sealData?.owner}
							</span>
							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle title_last">
							<div className="iconSection bg-orange  text-white text-center">
								<IoIosPaper />
							</div>

							<Renderif
								isTrue={stateSeals.sealData?.ocr !== null}
							>
								<span className="psc__text_normal t_span_text">
									{stateSeals.sealData?.ocr}
								</span>
							</Renderif>

							<Renderif
								isTrue={stateSeals.sealData?.ocr === null}
							>
								<span className="psc__text_normal t_span_text">
									Aún no hay datos de OCR
								</span>
							</Renderif>

							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle">
							Revisiones:
						</div>
						<div className="clear"></div>
					</div>

					<Renderif isTrue={state.revisions?.length === 0}>
						<div className="revisions_container">
							Aún no hay revisiones para este sello
						</div>
					</Renderif>

					<Renderif isTrue={state.revisions?.length > 0}>
						<div className="revisions_container">
							{state.revisions?.map((revision, index) => {
								return (
									<>
										<div
											className="revison_block"
											onClick={() =>
												showRevision(
													revision,
													index + 1
												)
											}
										>
											<div className="info">
												<div className="title_revision_no psc__section_subtitle text-white">
													<ImQrcode className="text-purple" />{" "}
													Revisión Número: {index + 1}
												</div>
											</div>
											<div className="info">
												<div className="title_revision_incidents psc__section_subtitle text-center text-white">
													Incidencias: &nbsp;
													{revision?.inc_id !==
													null ? (
														<>SI</>
													) : (
														<>No</>
													)}
												</div>
											</div>
											<div className="info">
												<div className="title_revision_images psc__section_subtitle text-white text-right">
													Imagenes: &nbsp;
													{revision?.inc_pictures !==
													null ? (
														<>Si</>
													) : (
														<>No</>
													)}
												</div>
											</div>
											<div className="clear"></div>
										</div>
									</>
								);
							})}
						</div>
					</Renderif>
				</div>
			</Modal>
			{/* <RevisionDetails /> */}
			{/* <IncidentsDetails /> */}
		</>
	);
}

export default SealsDetails;
