import { FiHome } from "react-icons/fi";

export const mainMenu = [
	{
		rol: 1,
		menu: [
			{
				key: "dashboard-key",
				item: "Dashboard",
				icon: "FiHome",
				active: "active",
				page: "dashboard",
				submenu: [],
			},
			{
				key: "profile-key",
				item: "Perfil",
				icon: "AiOutlineUser",
				page: "profile",
				active: "",
				submenu: [],
			},

			{
				key: "users-key",
				item: "Usuarios",
				icon: "AiOutlineUsergroupAdd",
				active: "",
				page: "users",
				submenu: [],
			},
			{
				key: "seals-key",
				item: "Sellos",
				icon: "TbQrcode",
				page: "seals",
				active: "",
				submenu: [],
			},
			{
				key: "close-sesion-key",
				item: "Cerrar Sesión",
				icon: "FiLogOut",
				active: "",
				page: "logout",
				submenu: [],
			},
		],
	},
	{
		rol: 2,
		menu: [
			{
				key: "dashboard-key",
				item: "Dashboard",
				icon: "FiHome",
				active: "active",
				page: "dashboard",
				submenu: [],
			},
			{
				key: "profile-key",
				item: "Perfil",
				icon: "AiOutlineUser",
				page: "profile",
				active: "",
				submenu: [],
			},

			{
				key: "seals-key",
				item: "Sellos",
				icon: "TbQrcode",
				page: "seals",
				active: "",
				submenu: [],
			},
			{
				key: "close-sesion-key",
				item: "Cerrar Sesión",
				icon: "FiLogOut",
				active: "",
				page: "logout",
				submenu: [],
			},
		],
	},
];
