import userEvent from "@testing-library/user-event";
import { useEffect, useState } from "react";
import infoContext from "../context/infoContext";
import { Service } from "../services/api";

const InfoState = ({ children }) => {
	const [state, setState] = useState({
		info: null,
	});

	useEffect(() => {
		let _info = localStorage.getItem("infoSeals");
		let info = JSON.parse(_info);
		if (_info) {
			state.info = info;
			setState({ ...state });
		}
	}, []);

	const setInfo = (info) => {
		setState({ ...state, info });
	};

	return (
		<infoContext.Provider
			value={{
				...state,
				setInfo,
			}}
		>
			{children}
		</infoContext.Provider>
	);
};
export default InfoState;
