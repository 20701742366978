/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/profile.css";
import { useNavigate } from "react-router-dom";
import { FiLock } from "react-icons/fi";
import DataContext from "../../context/dataContext";
import IconButton from "../../components/buttons/iconButton";
import AuthContext from "../../context/authContext";
import ComponentContext from "../../context/componentContext";
import { Service } from "../../services/api";

var sha1 = require("sha1");

function ProfileUpdateForm() {
	const navigate = useNavigate();
	const { user, setUser } = useContext(AuthContext);
	const { setStateComp, stateComp } = useContext(ComponentContext);

	const [state, setState] = useState({
		countries: [],
		firstname: "",
		firstnameError: "",
		lastname: "",
		lastnameError: "",
		userPhone: "",
		userPhoneError: "",
		userEmail: "",
		userEmailError: "",
		password: "",
		passwordError: "",
		rolType: "",
		countryType: 0,
	});

	const { setModalUpdatePassword } = useContext(DataContext);

	useEffect(() => {
		const data = async () => {
			let _countries = await Service("GET", "countries");
			console.log("===COUNTRIES====", _countries);

			setState({
				...state,
				countries: _countries.data.response,
				firstname: user?.firstname,
				lastname: user?.lastname,
				userEmail: user?.email,
				userPhone: user?.phone,
				countryType: user?.country,
			});
			console.log("user-->", user);
		};
		data();
	}, []);

	const handleChange = (event) => {
		if (event.target.name === "firstname") {
			const onlyText = event.target.value.replace(/[^a-zA-Z' ']/gi, "");

			setState({
				...state,
				firstname: onlyText,
				firstnameError: "",
			});

			// setfirstname(onlyText);
			// setfirstnameError("");
		}

		if (event.target.name === "lastname") {
			const onlyText = event.target.value.replace(/[^a-zA-Z' ']/gi, "");
			setState({
				...state,
				lastname: onlyText,
				lastnameError: "",
			});
			// setlastname(onlyText);
			// setlastnameError("");
		}

		if (event.target.name === "userphone") {
			const onlyNumbers = event.target.value.replace(/\D/g, "");

			setState({
				...state,
				userPhone: onlyNumbers,
				userPhoneError: "",
			});

			// setuserphone(onlyNumbers);
			// setuserPhoneError("");
		}

		if (event.target.name === "password") {
			setState({
				...state,
				password: event.target.value,
				passwordError: "",
			});
			// setpassword(event.target.value);
			// setpasswordError("");
		}

		if (event.target.name === "userEmail") {
			setState({
				...state,
				userEmail: event.target.value,
				userEmailError: "",
			});
			// setuserEmail(event.target.value);
			// setuserEmailError("");
		}

		if (event.target.name === "countryType") {
			//setCountryType(event.target.value);

			setState({
				...state,
				countryType: event.target.value,
			});
		}
	};

	const emailValidation = () => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!state?.userEmail || regex.test(state?.userEmail) === false) {
			console.log("El correo no es válido");
			//setuserEmailError("Introduce un correo válido");

			setState({
				...state,
				userEmailError: "Introduce un correo válido",
			});

			return false;
		} else {
			//setuserEmailError("");
			setState({
				...state,
				userEmailError: "",
			});
		}

		return true;
	};

	const userValidation = () => {
		if (!state?.firstname) {
			console.log("error firstname");

			setState({
				...state,
				firstnameError: "Introduce el nombre del usuario",
			});

			console.log("state-->", state);

			return false;
		} else {
			setState({
				...state,
				firstnameError: "",
			});
		}

		if (!state?.lastname) {
			console.log("error lastname");

			setState({
				...state,
				lastnameError: "Introduce el apellido del usuario",
			});
			//setlastnameError("Introduce el apellido del usuario");
			return false;
		} else {
			setState({
				...state,
				lastnameError: "",
			});
			//setlastnameError("");
		}
		if (!state?.userPhone) {
			//setuserPhoneError("Introduce el teléfono del usuario");
			setState({
				...state,
				userPhoneError: "Introduce el teléfono del usuario",
			});
			return false;
		} else {
			setState({
				...state,
				userPhoneError: "",
			});
			//setuserPhoneError("");
		}

		if (!state?.userEmail) {
			setState({
				...state,
				userEmailError: "Introduce el correo del usuario",
			});
			//setuserEmailError("Introduce el correo del usuario");
			return false;
		} else {
			emailValidation();
		}

		return true;
	};

	const updateUser = async (event) => {
		event.preventDefault();

		if (emailValidation() && userValidation()) {
			const userData = {
				id: user?.id,
				firstname: state?.firstname,
				lastname: state?.lastname,
				email: state?.userEmail,
				phone: state?.userPhone,
				rol: user?.rol,
				ID_country: parseInt(state?.countryType),
			};

			console.log("userData", userData);

			let _update = await Service("PUT", `users/updateByID`, userData);

			console.log("===UPDATE====", _update.data.response);

			if (_update.data.response.error === false) {
				setStateComp({
					...stateComp,
					modalStatus: "success",
					modalMessageTitle: "Operación realizada con éxito:",
					modalMessageDescription: "Se han actualizado los datos",
					modalMessage: true,
					sealModalCreate: false,
				});

				let _userInfo = await Service(
					"GET",
					`users/email/${state?.userEmail}`
				);

				console.log("_userInfo", _userInfo);

				if (_userInfo.data.status === 200) {
					console.log("_userInfo", _userInfo.data);
					localStorage.setItem(
						"user",
						JSON.stringify(_userInfo.data)
					);
					setUser(_userInfo.data);
				}
			}
		} else {
			console.log("NO SE VALIDO");
		}
	};

	return (
		<>
			<div className="psc__modal_btn_content_center btn-center">
				<div className="form">
					<form
						className="psc__form_modal psc__form_modal_update"
						autoComplete="off"
						onSubmit={updateUser}
					>
						<div className="PSC__form_50">
							<div className="form_content">
								<label className="mb-10">Nombre(s)</label>
								<input
									type="text"
									name="firstname"
									placeholder=""
									value={state?.firstname}
									className="mb-20"
									onChange={handleChange}
									maxLength={20}
								/>
								<div className="f_error">
									{state?.firstnameError}
								</div>

								<label>Correo Electrónico</label>
								<input
									type="text"
									name="userEmail"
									placeholder=""
									className="mb-20"
									value={state?.userEmail}
									onChange={handleChange}
								/>
								<div className="f_error">
									{state?.userEmailError}
								</div>

								<label>País</label>
								<select
									name="countryType"
									onChange={handleChange}
									className="mb-20"
									value={state?.countryType}
								>
									{state.countries?.map((country, index) => {
										return (
											<>
												<option
													value={country.id}
													key={index}
												>
													{country.name}
												</option>
											</>
										);
									})}
								</select>

								<div className="clear"></div>
							</div>

							<IconButton
								icon={<FiLock />}
								className="btn__icon_type_2 mr-10  btn__purple mb-30"
								color="#9899a1"
								width="210px"
								title="Actualizar Contraseña"
								onClick={() => setModalUpdatePassword(true)}
							/>
						</div>

						<div className="PSC__form_50">
							<div className="form_content">
								<label>Apellido(s)</label>
								<input
									type="text"
									name="lastname"
									placeholder=""
									className="mb-20"
									value={state?.lastname}
									maxLength={30}
									onChange={handleChange}
								/>
								<div className="f_error">
									{state?.lastnameError}
								</div>

								<label>Número de teléfono</label>
								<input
									type="text"
									name="userphone"
									placeholder=""
									className="mb-20"
									value={state?.userPhone}
									onChange={handleChange}
									maxLength={10}
								/>
								<div className="f_error">
									{state?.userPhoneError}
								</div>

								<div className="clear"></div>
							</div>
						</div>
						<div className="clear"></div>

						<input
							type="submit"
							value="Guardar cambios"
							className="btn__normal btn__blue_2 transition"
						/>
					</form>
				</div>
			</div>
		</>
	);
}

export default ProfileUpdateForm;
