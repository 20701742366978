/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { Modal } from "antd";
import { Upload } from "antd";
import ComponentContext from "../../context/componentContext";
import { Service } from "../../services/api";
import SealsContext from "../../context/sealsContext";

function SealsAssign(props) {
	const { setStateComp, stateComp } = useContext(ComponentContext);

	const { setStateSeals, stateSeals } = useContext(SealsContext);

	const [state, setState] = useState({
		seals_owners: [],
	});

	const [idSeal, setIDSeal] = useState("");

	const [sealType, setSealType] = useState(1);
	const [sealOwner, setSealOwner] = useState(1);
	const [sealStatus, setSealStatus] = useState(1);

	const { Dragger } = Upload;

	const closeModal = () => {
		setStateComp({
			...stateComp,
			sealModalAssign: false,
		});

		setSealType(1);
		setSealOwner(1);
		setSealStatus(1);
	};

	const handleChange = (event) => {
		if (event.target.name === "sealOwner") {
			setSealOwner(event.target.value);
		}
	};

	const assignSeals = async (event) => {
		event.preventDefault();

		//console.log("stateSeals.selectedRows", stateSeals.selectedRows);

		let rows = stateSeals.selectedRows;
		let dataSelected = [];

		rows?.map((row, i) => {
			console.log("row: ", row.id, "status: ", row.status_id);

			if (row.status_id !== 3 && row.status_id !== 4) {
				dataSelected.push(row.id);
			}
		});

		console.log("dataSelected", dataSelected);

		let _seals = await Service(
			"PUT",
			`seals/assign/group/${sealOwner}`,
			dataSelected
		);
		console.log("===SEALS Block====", _seals.data.response);

		if (_seals.data.response.error === true) {
			setStateComp({
				...stateComp,
				modalStatus: "error",
				modalMessageTitle: "La operación ha fallado:",
				modalMessageDescription: _seals.data.response.message,
				modalMessage: true,
				sealModalCreate: false,
				sealModalAssign: false,
			});
		} else {
			setStateComp({
				...stateComp,
				modalStatus: "success",
				modalMessageTitle: "Operación realizada con éxito:",
				modalMessageDescription: _seals.data.response.message,
				modalMessage: true,
				sealModalCreate: false,
				sealModalAssign: false,
			});

			setStateSeals({
				selectedRowKeys: [],
			});

			setStateSeals({
				updateState: !stateSeals.updateState,
			});
		}
	};

	useEffect(() => {
		const data = async () => {
			let _seals_owners = await Service("GET", "seals/owners");
			console.log("===SEALS OWNERS ====", _seals_owners);

			setState({
				...state,
				seals_owners: _seals_owners.data.response,
			});
		};
		data();
	}, []);

	return (
		<>
			<Modal
				title="Asignar Sello"
				visible={stateComp.sealModalAssign}
				onCancel={closeModal}
				cancelButtonProps={{
					style: { display: "none" },
				}}
				okButtonProps={{
					style: { display: "none" },
				}}
			>
				<>
					<div className="psc__modal_info">
						Elige la la empresa a la cual será asignado los
					</div>
				</>

				<>
					<div className="psc__modal_btn_content_center btn-center">
						<div className="form">
							<form
								className="psc__form_modal"
								autoComplete="off"
								onSubmit={assignSeals}
							>
								<select
									name="sealOwner"
									onChange={handleChange}
									className="mb-20"
									value={sealOwner}
								>
									{state.seals_owners?.map((owner, index) => {
										return (
											<>
												<option
													value={owner.id}
													key={index}
												>
													{owner.name}
												</option>
											</>
										);
									})}
								</select>

								<input
									type="submit"
									value="Asignar Sellos"
									className="btn__normal btn__blue transition"
								/>
							</form>
						</div>

						<div className="clear"></div>
					</div>
				</>
			</Modal>
		</>
	);
}

export default SealsAssign;
