/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import logo from "../../assets/images/logos/Logo_PSC_WHITE.png";
import "../../assets/css/login.css";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/icomoon/eye";
import { eyeBlocked } from "react-icons-kit/icomoon/eyeBlocked";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import { Upload, message } from "antd";
import AuthContext from "../../context/authContext";
import DataContext from "../../context/dataContext";
import ComponentContext from "../../context/componentContext";

function LoginForm() {
	const [type, setType] = useState("password");
	const [icon, setIcon] = useState(eyeBlocked);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(false);
	const [loginErrorUpdate, setLoginErrorUpdate] = useState(false);
	const [emailError, setEmailError] = useState("");
	const [passwordError, setpasswordError] = useState("");
	const [errorTitle, setErrorTitle] = useState("Credenciales incorrectas.");
	const [errorMessage, setErrorMessage] = useState(
		"Revisa tu correo o contraseña."
	);

	const {
		setDisplaySiderBar,
		postData,
		UserToken,
		compareDate,
		updatePasswordModal,
		SetUpdatePasswordModal,
	} = useContext(DataContext);

	const { stateComp, setStateComp } = useContext(ComponentContext);

	const { user, setUser } = useContext(AuthContext);

	const navigate = useNavigate();
	const showPassword = () => {
		if (type === "password") {
			setIcon(eye);
			setType("text");
		} else {
			setIcon(eyeBlocked);
			setType("password");
		}
	};

	const handleChange = (event) => {
		//setPassword(event.target.value);
		if (event.target.name === "email") {
			setEmail(event.target.value);
			setEmailError("");
		}

		if (event.target.name === "password") {
			setPassword(event.target.value);
			setpasswordError("");
		}
	};

	const emailValidation = () => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!email || regex.test(email) === false) {
			console.log("El correo no es válido");
			setEmailError("Introduce un correo válido");
			return false;
		} else {
			setEmailError("");
		}
		return true;
	};

	const passValidation = () => {
		if (!password) {
			console.log("Introduce el password");
			setpasswordError("Introduce el password");
			return false;
		} else {
			setpasswordError("");
		}
		return true;
	};

	const loggin = (event) => {
		event.preventDefault();

		if (emailValidation() && passValidation()) {
			const userLogin = {
				email: email,
				password: password,
			};
			console.log("loggin", userLogin);

			postData("users/login", userLogin).then((login) => {
				console.log("====LOGIN===", login);

				if (login.data.status === 200) {
					if (login.data.rol === 1 || login.data.rol === 2) {
						localStorage.setItem(
							"user",
							JSON.stringify(login.data)
						);
						setUser(login.data);
						navigate("/dashboard");
					} else {
						setErrorTitle("No tines permisos para acceder");
						setErrorMessage(
							"Ponte en contacto con el administrador web"
						);
						setLoginError(true);
					}
				} else if (login.data.status === 500) {
					setErrorTitle("Credenciales incorrectas.");
					setErrorMessage("Revisa tu correo o contraseña.");
					setLoginError(true);
				} else {
					setLoginError(true);
					setErrorTitle("Credenciales incorrectas.");
					setErrorMessage("Revisa tu correo o contraseña.");
					setLoginError(true);
				}
			});
		}
	};
	return (
		<>
			<div className="block_form">
				<div className="psc__center_grid">
					<div className="logo">
						<img src={logo} alt="synci-logo"></img>
					</div>

					<div className="form_container">
						{loginError ? (
							<>
								<div className="errorMessage">
									<div className="errorData">
										<div className="icon">
											<div className="icon-warning"></div>
										</div>

										<div className="info">
											<div className="message">
												{errorTitle}
											</div>
											<div className="actions">
												{errorMessage}
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							""
						)}

						{loginErrorUpdate ? (
							<>
								<div className="errorMessage pointer">
									<div className="errorData">
										<div className="icon">
											<div className="icon-warning"></div>
										</div>

										<div className="info">
											<div className="message">
												Tu contraseña ha caducado.
											</div>
											<div
												className="actions"
												onClick={() =>
													SetUpdatePasswordModal(true)
												}
											>
												Clic aquí para reestablecer
											</div>
										</div>
									</div>
									<div className="clear"></div>
								</div>
							</>
						) : (
							""
						)}

						<form
							className="psc__form_login"
							autoComplete="off"
							onSubmit={loggin}
						>
							<input
								type="text"
								name="email"
								placeholder="Correo Electrónico"
								value={email}
								onChange={handleChange}
							/>
							<span className="f_error">{emailError}</span>
							<div className="password-block">
								<input
									type={type}
									name="password"
									placeholder="Contraseña"
									value={password}
									onChange={handleChange}
								/>
								<span onClick={showPassword}>
									<Icon icon={icon} size="22" />
								</span>
							</div>
							<span className="f_error">{passwordError}</span>

							<span>
								¿Olvidaste tu contraseña?{" "}
								<a
									href="#pass"
									className="transition"
									onClick={() =>
										setStateComp({
											...stateComp,
											passwordModalUpdate: true,
										})
									}
								>
									Clic aquí
								</a>
							</span>

							<input
								type="submit"
								value="Login"
								className="transition"
							/>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default LoginForm;
