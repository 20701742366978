/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/profile.css";

import { useNavigate } from "react-router-dom";
import HeaderDashboard from "../../components/HeaderDashboard";
import DataContext from "../../context/dataContext";
import ProfileData from "./ProfileData";
import ProfileDataUpdate from "./ProfileDataUpdate";
import UpdatePaswordModal from "./UpdatePasswordModal";
import ModalUpdatePhoto from "./ModalUpdatePhoto";

function Profile() {
	const navigate = useNavigate();
	const { updateUserInfo, data } = useContext(DataContext);

	useEffect(() => {}, []);

	return (
		<>
			<UpdatePaswordModal />
			<ModalUpdatePhoto />
			<HeaderDashboard />
			{!updateUserInfo ? <ProfileData /> : <ProfileDataUpdate />}
		</>
	);
}

export default Profile;
