/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "../../assets/css/dashboard.css";
import { Link, Redirect, Navigate, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderDashboard from "../../components/HeaderDashboard";

function SealsOwners() {
	const navigate = useNavigate();

	useEffect(() => {
		const IsLoggin = localStorage.getItem("setLogin");

		return () => {};
	}, []);

	return (
		<>
			<HeaderDashboard />
			<div className="pcs__center_content">
				<div className="section_title text-white psc__section_title">
					SealsOwners
				</div>
			</div>
		</>
	);
}

export default SealsOwners;
