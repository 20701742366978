/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import { HiOutlineTicket } from "react-icons/hi";
import { GiBattleship } from "react-icons/gi";
import { BsCheck2Circle } from "react-icons/bs";
import { BiBuilding } from "react-icons/bi";
import moment from "moment";
import { Select } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import infoContext from "../../context/infoContext";
import AuthContext from "../../context/authContext";
import SealsState from "../../context/sealsContext";

const { Option } = Select;
ChartJS.register(ArcElement, Tooltip, Legend);

function DashboardGraphs() {
	const { stateSeals, setStateSeals } = useContext(SealsState);

	const [state, setState] = useState({
		labelStates: ["En tránsito", "Completados"],
	});

	const { user } = useContext(AuthContext);

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};
	const option = {
		legend: {
			position: "bottom",
			labels: {
				boxWidth: 10,
			},
		},
		label: {
			position: "bottom",
		},
	};
	const data = {
		labels: state.labelStates,
		datasets: [
			{
				label: "",
				data: [
					stateSeals?.sealAllData?.transit_seals,
					stateSeals?.sealAllData?.complete_seals,
				],
				backgroundColor: ["#212332", "#22b07d"],
				borderColor: ["#212332", "#22b07d"],
				borderWidth: 1,
			},
		],
	};

	useEffect(() => {
		const data = async () => {};

		data();

		return () => {};
	}, []);
	return (
		<>
			<div className="graphs_section text-gray">
				<div className="graphs_container bg-dark-blue-secondary">
					<div className="graphs_header">
						<div className="header_company">
							<div className="title_company text-white psc__section_title">
								Sellos emitidos
							</div>
							<div className="select_filer_companies mb-20">
								<Select
									placeholder="Filtrar por"
									style={{
										width: 160,
									}}
									onChange={handleChange}
								>
									<Option value="1">En tránsito</Option>
									<Option value="2">Completados</Option>
								</Select>
							</div>
							<div className="clear"></div>
							<div className="line"></div>
						</div>
						<div className="clear"></div>
					</div>
					<div className="graps_data_info">
						<div className="data_seal_option text-gray psc__text_normal">
							<div className="description description_1">
								En tránsito{" "}
							</div>
							<div className="number">
								{stateSeals?.sealAllData?.transit_seals}
							</div>
							<div className="clear"></div>
						</div>

						<div className="data_seal_option">
							<div className="description description_2">
								Completados{" "}
							</div>
							<div className="number">
								{stateSeals?.sealAllData?.complete_seals}
							</div>
							<div className="clear"></div>
						</div>
						<div className="line "></div>
					</div>

					<div className="graph_circle_block">
						<Doughnut data={data} options={option} />
					</div>
				</div>
			</div>
		</>
	);
}

export default DashboardGraphs;
