/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import { Modal } from "antd";
import { FaUser, FaComment, FaExclamationTriangle } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { Image } from "antd";
import { urlFiles, urlService } from "../../services/UrlService";
import { MdLocationPin, MdOutlineCalendarToday } from "react-icons/md";
import "../../assets/css/sealsDetails.css";
import DataContext from "../../context/dataContext";
import { Service } from "../../services/api";
import ComponentContext from "../../context/componentContext";
import SealsContext from "../../context/sealsContext";
import Renderif from "../../components/Renderif";
import moment from "moment";

function IncidentsDetails() {
	const { setModalIncidents, revisionDetail } = useContext(DataContext);
	const [incidentPictures, setIncidentPictures] = useState([]);
	const { stateComp, setStateComp } = useContext(ComponentContext);
	const { setStateSeals, stateSeals } = useContext(SealsContext);

	const [state, setState] = useState({
		incidensts: [],
	});

	useEffect(() => {
		const data = async () => {
			console.log("stateSeals?.incidentData?", stateSeals?.incidentData);
			if (stateSeals?.incidentData?.pictures) {
				setIncidentPictures(
					JSON.parse(stateSeals?.incidentData?.pictures)
				);
			} else {
				setIncidentPictures([]);
			}
		};
		data();
	}, [!stateComp?.incidentsModalDetails]);

	const closeModal = () => {
		setStateComp({
			...stateComp,
			incidentsModalDetails: false,
			revisionsModalDetails: true,
		});
	};

	return (
		<>
			<Modal
				title=""
				centered
				visible={stateComp.incidentsModalDetails}
				onCancel={closeModal}
				width={1000}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
			>
				<div className="section_seals_details bg-white text-black">
					<div className="seal_id">
						<div className="title psc__section_subtitle_big">
							<Renderif
								isTrue={stateSeals?.incidentData?.type === 1}
							>
								Incidencia #{stateSeals?.incidentNumber}: Sello
								Roto
							</Renderif>
							<Renderif
								isTrue={stateSeals?.incidentData?.type === 2}
							>
								Incidencia #{stateSeals?.incidentNumber}: No
								tiene Sello
							</Renderif>
							<Renderif
								isTrue={stateSeals?.incidentData?.type === 3}
							>
								Incidencia #{stateSeals?.incidentNumber}: Sello
								no corresponde
							</Renderif>
							<Renderif
								isTrue={stateSeals?.incidentData?.type === 4}
							>
								Incidencia #{stateSeals?.incidentNumber}: No
								corresponde el OCR
							</Renderif>

							<Renderif
								isTrue={stateSeals?.incidentData?.type === 5}
							>
								Incidencia #{stateSeals?.incidentNumber}: Daño
								del contenedor
							</Renderif>

							<Renderif
								isTrue={stateSeals?.incidentData?.type === 6}
							>
								Incidencia #{stateSeals?.incidentNumber}: Combo
								- 20 pies con puertas no accesibles
							</Renderif>
						</div>
					</div>

					<div className="status_Data">
						<div className="title psc__section_subtitle ">
							<div className="iconSection bg-sky-blue text-white text-center">
								<FaComment />
							</div>
							<span className="psc__text_normal t_span_text">
								Descripción:{" "}
								{stateSeals?.incidentData?.description}
							</span>
							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle">
							<div className="iconSection bg-orange text-white text-center">
								<MdOutlineCalendarToday />
							</div>
							<span className="psc__text_normal t_span_text">
								Fecha:{" "}
								{moment(stateSeals?.incidentData?.create_date)
									.utc()
									.format("DD/MM/YYYY HH:mm A")}
							</span>
							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle ">
							<div className="iconSection bg-green  text-white text-center">
								<FaUser />
							</div>
							<span className="psc__text_normal t_span_text">
								Capturista: {stateSeals?.revisionData?.user}
							</span>
							<div className="clear"></div>
						</div>

						<div className="title psc__section_subtitle title_last">
							<div className="iconSection bg-red  text-white text-center">
								<FaExclamationTriangle />
							</div>
							<span className="psc__text_normal t_span_text">
								<Renderif
									isTrue={
										stateSeals?.incidentData?.type === 1
									}
								>
									Sello Roto
								</Renderif>
								<Renderif
									isTrue={
										stateSeals?.incidentData?.type === 2
									}
								>
									{" "}
									No tiene Sello
								</Renderif>
								<Renderif
									isTrue={
										stateSeals?.incidentData?.type === 3
									}
								>
									Sello no corresponde
								</Renderif>
								<Renderif
									isTrue={
										stateSeals?.incidentData?.type === 4
									}
								>
									{" "}
									No corresponde el OCR
								</Renderif>

								<Renderif
									isTrue={
										stateSeals?.incidentData?.type === 5
									}
								>
									{" "}
									Daño del contenedor
								</Renderif>

								<Renderif
									isTrue={
										stateSeals?.incidentData?.type === 6
									}
								>
									{" "}
									Combo - 20 pies con puertas no accesibles
								</Renderif>
							</span>
							<div className="clear"></div>
						</div>

						<div className="clear"></div>
					</div>

					<div className="captures_container">
						<div className="title psc__section_subtitle">
							Capturas:
						</div>

						<Renderif isTrue={incidentPictures.length === 0}>
							<div className="revisions_container">
								No hay capturas para esta incidencia
							</div>
						</Renderif>

						<Renderif isTrue={incidentPictures.length > 0}>
							<div className="revisions_container mb-20">
								<Image.PreviewGroup>
									{incidentPictures?.map((picture, index) => {
										return (
											<>
												<Image
													width={120}
													src={`
												${urlService}/uploads/incidents/${picture.name}
												`}
												/>
											</>
										);
									})}
								</Image.PreviewGroup>
							</div>
						</Renderif>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default IncidentsDetails;
