/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import "../assets/css/seals.css";
import { BiBell } from "react-icons/bi";
import profile from "../assets/images/generals/profilep.png";
import DataContext from "../context/dataContext";
import AuthContext from "../context/authContext";
import SearchForm from "./SearchForm";
import { urlService, DashboardURL } from "../services/UrlService";

function HeaderDashboard() {
	const userName = localStorage.getItem("userName");
	const userRol = localStorage.getItem("userRol");

	const {
		getData,
		getDataCountries,
		data,
		data_user,
		updateUserInfo,
		setUpdateUserInfo,
		setGeneralUserInfo,
		UploadProps,
		setModalUpdatePhoto,
		getDataGeneral,
		data_countries,
	} = useContext(DataContext);

	const [userInfo, setUserInfo] = useState([]);

	const { user } = useContext(AuthContext);

	useEffect(() => {
		return () => {};
	}, []);

	return (
		<>
			<div className="psc__header">
				<div className="InputSearch">
					<SearchForm
						width="90%"
						height="50px"
						placeholder="Buscar empresa..."
						color="#FFF"
						background="#2a2d3e"
						borderColor="#2a2d3e"
						endpoint="other"
					/>
				</div>

				<div className="Profile">
					<div className="notify">
						<BiBell />
					</div>

					<div className="profilePicture">
						<div className="info">
							<div className="title ">{user?.fullname}</div>
							<div className="role">{userRol}</div>
						</div>

						<div className="photo">
							<img
								src={
									urlService + "/images/users/" + user?.photo
								}
								alt="foto"
							/>
						</div>
						<div className="clear"></div>
					</div>
				</div>

				<div className="clear"></div>
			</div>
		</>
	);
}

export default HeaderDashboard;
