/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import Renderif from "../../components/Renderif";
import ComponentContext from "../../context/componentContext";
import SealsContext from "../../context/sealsContext";
import AuthContext from "../../context/authContext";
import { Service } from "../../services/api";

import "antd/dist/antd.css";

function ActionsGroup() {
	const { sealModalCreate, setStateComp, stateComp } =
		useContext(ComponentContext);
	const {
		setStateSeals,
		sealsName,
		stateSeals,
		selectedRowKeys,
		selectedRows,
		seals,
	} = useContext(SealsContext);

	const { user } = useContext(AuthContext);

	useEffect(() => {}, []);

	const DeleteType = async (type) => {
		if (type === 4) {
			console.log("asignar...");
			setStateComp({
				...stateComp,
				modalStatus: "403",
				modalMessageTitle: "No tienes permisos:",
				modalMessageDescription:
					"No tienes permisos para realizar esta operación",
				modalMessage: true,
				sealModalCreate: false,
			});
		}

		if (selectedRowKeys?.length === 0) {
			setStateComp({
				modalStatus: "error",
				modalMessageTitle: "No hay datos",
				modalMessageDescription:
					"Selecciona los sellos en los que deseas efectar la operación",
				modalMessage: true,
				sealModalCreate: false,
			});
		} else {
			if (type === 1) {
				let _seals = await Service(
					"PUT",
					`seals/deleteblock/group/delete`,
					selectedRowKeys
				);
				console.log("===SEALS DELETE====", _seals.data.response);

				console.log("selectedRows", selectedRows);

				let sealsDeleteMultiple = [];
				selectedRows?.map((seals, i) => {
					let sealDataDelete = {
						number: seals.number,
						type: seals.type_id,
						status: seals.status_id,
						ID_sealowner: seals.owner_id,
						ocr: seals.ocr,
					};
					sealsDeleteMultiple.push(sealDataDelete);
				});

				let _sealsDeleteMultiple = await Service(
					"POST",
					`seals/multipleDelete`,
					sealsDeleteMultiple
				);

				console.log("sealsDeleteMultiple", _sealsDeleteMultiple);

				if (_seals.data.response.error === true) {
					setStateComp({
						modalStatus: "error",
						modalMessageTitle: "La operación ha fallado:",
						modalMessageDescription: _seals.data.response.message,
						modalMessage: true,
						sealModalCreate: false,
					});
				} else {
					setStateComp({
						modalStatus: "success",
						modalMessageTitle: "Operación realizada con éxito:",
						modalMessageDescription: _seals.data.response.message,
						modalMessage: true,
						sealModalCreate: false,
					});

					setStateSeals({
						updateState: !stateSeals.updateState,
					});

					// getData("seals").then(() => {
					// 	setInitData(true);
					// });
				}
			}

			if (type === 2) {
				console.log("asignar...");
				setStateComp({
					...stateComp,
					sealModalAssign: true,
				});
			}

			if (type === 3) {
				let _seals = await Service(
					"PUT",
					`seals/deleteblock/group/block`,
					selectedRowKeys
				);
				console.log("===SEALS Block SSS====", _seals.data.response);

				if (_seals.data.response.error === true) {
					setStateComp({
						modalStatus: "error",
						modalMessageTitle: "La operación ha fallado:",
						modalMessageDescription: _seals.data.response.message,
						modalMessage: true,
						sealModalCreate: false,
					});
				} else {
					setStateComp({
						modalStatus: "success",
						modalMessageTitle: "Operación realizada con éxito:",
						modalMessageDescription: _seals.data.response.message,
						modalMessage: true,
						sealModalCreate: false,
					});

					setStateSeals({
						updateState: !stateSeals.updateState,
					});
				}
			}
		}
	};

	const NotPermisions = () => {
		console.log("asignar...");
		setStateComp({
			modalStatus: "403",
			modalMessageTitle: "No tienes permisos:",
			modalMessageDescription:
				"No tienes permisis para realizar esta operación",
			modalMessage: true,
			sealModalCreate: false,
		});
	};

	return (
		<>
			
				<div className="options">
					<div
						className="btns transition btn_border_r psc__text_normal"
						onClick={() => DeleteType(1)}
					>
						Eliminar Grupo
					</div>
					<div
						className="btns transition btn_border_r psc__text_normal"
						onClick={() => DeleteType(2)}
					>
						Asignar Grupo
					</div>
					<div
						className="btns transition psc__text_normal"
						onClick={() => DeleteType(3)}
					>
						Bloquear Grupo
					</div>
				</div>
			

			{/* <Renderif isTrue={user?.rol === 2}>
				<div className="options ">
					<div
						className="btns  transition btn_border_r_disabled psc__text_normal"
						onClick={() => NotPermisions()}
					>
						Eliminar Grupo
					</div>
					<div
						className="btns  transition btn_border_r_disabled psc__text_normal"
						onClick={() => NotPermisions()}
					>
						Asignar Grupo
					</div>
					<div
						className="btns  transition psc__text_normal"
						onClick={() => NotPermisions()}
					>
						Bloquear Grupo
					</div>
				</div>
			</Renderif> */}

			<div className="clear"></div>
		</>
	);
}

export default ActionsGroup;
