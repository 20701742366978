/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "antd/dist/antd.css";
import "../../assets/css/seals.css";
import { BiSearch, BiBell } from "react-icons/bi";
import profile from "../../assets/images/generals/profilep.png";
import DataContext from "../../context/dataContext";
import UsersTab from "./UsersTabs";
import SearchForm from "../../components/SearchForm";
import HeaderDashboard from "../../components/HeaderDashboard";
import ModalCreate from "./ModalCreate";
import ModalBlockDelete from "./ModalBlockDelete";
import UpdateUser from "./UpdateUser";

function Users() {
	const { data, getData, getDataCountries, getDataRols } =
		useContext(DataContext);
	const [initData, setInitData] = useState(false);

	useEffect(() => {
		getDataCountries(`countries`).then(() => {});
		getDataRols(`rols`).then(() => {});
	}, []);

	return (
		<>
			<HeaderDashboard />
			<UsersTab />
			<ModalCreate />
			<ModalBlockDelete />
			<UpdateUser />
		</>
	);
}

export default Users;
