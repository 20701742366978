/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "../../assets/css/dashboard.css";
import { HiOutlineTicket } from "react-icons/hi";
import { GiBattleship } from "react-icons/gi";
import { BsCheck2Circle } from "react-icons/bs";
import { BiBuilding } from "react-icons/bi";
import moment from "moment";
import { Select } from "antd";
import AnimatedNumber from "animated-number-react";

import { Service } from "../../services/api";
import AuthContext from "../../context/authContext";
import SealsState from "../../context/sealsContext";

const { Option } = Select;
function DashboardInfoBlocks() {
	const { user } = useContext(AuthContext);
	const { stateSeals, setStateSeals } = useContext(SealsState);

	const [state, setState] = useState({
		allData: [],
	});

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};

	const formatValue = (value) => value.toFixed(0);

	useEffect(() => {
		const data = async () => {
			let sealOwner = user?.id_sealowner;
			let _sealsData = await Service(
				"GET",
				`seals/alldataSeals/${sealOwner}`
			);
			console.log("===SEALS DATA====", _sealsData);

			setStateSeals({
				...stateSeals,
				sealAllData: _sealsData.data.response,
			});
		};

		data();

		return () => {};
	}, []);

	return (
		<>
			<div className="home__section_data">
				<div className="header_section">
					<div className="btn__icon_date text-gray ">
						Fecha: {moment(Date.now()).format("DD/MM/YYYY")}
					</div>

					<div className="select_filer_dates">
						<Select
							defaultValue="1"
							style={{
								width: 160,
							}}
							onChange={handleChange}
						>
							<Option value="1">Últimos 30 días</Option>
							<Option value="2">Hoy</Option>
						</Select>
					</div>
					<div className="clear"></div>
				</div>

				<div className="info_blocks">
					<div className="block">
						<div className="data bg-dark-blue-secondary">
							<div className="info psc__center_grid_full text-gray">
								<div className="icon">
									<div className="icon bg-purple">
										<BiBuilding />
									</div>
								</div>
								<div className="numbers">
									<div className="number text-white">
										<AnimatedNumber
											value={
												stateSeals?.sealAllData
													?.sealowners
											}
											formatValue={formatValue}
										/>
									</div>
									<div className="description">Empresas</div>
								</div>

								<div className="clear"></div>
							</div>
						</div>
					</div>
					<div className="block">
						<div className="data bg-dark-blue-secondary">
							<div className="info psc__center_grid_full text-gray">
								<div className="icon bg-orange">
									<HiOutlineTicket />
								</div>

								<div className="numbers">
									<div className="number text-white">
										<AnimatedNumber
											value={
												stateSeals?.sealAllData
													?.generate_seals
											}
											formatValue={formatValue}
										/>
									</div>
									<div className="description">
										Sellos generados
									</div>
								</div>

								<div className="clear"></div>
							</div>
						</div>
					</div>
					<div className="block">
						<div className="data bg-dark-blue-secondary">
							<div className="info psc__center_grid_full text-gray">
								<div className="icon bg-sky-blue">
									<GiBattleship />
								</div>

								<div className="numbers">
									<div className="number text-white">
										<AnimatedNumber
											value={
												stateSeals?.sealAllData
													?.transit_seals
											}
											formatValue={formatValue}
										/>
									</div>
									<div className="description">
										Sellos en transito
									</div>
								</div>

								<div className="clear"></div>
							</div>
						</div>
					</div>
					<div className="block">
						<div className="data bg-dark-blue-secondary">
							<div className="info psc__center_grid_full text-gray">
								<div className="icon bg-green">
									<BsCheck2Circle />
								</div>
								<div className="numbers">
									<div className="number text-white">
										<AnimatedNumber
											value={
												stateSeals?.sealAllData
													?.complete_seals
											}
											formatValue={formatValue}
										/>
									</div>
									<div className="description">
										Sellos completados
									</div>
								</div>

								<div className="clear"></div>
							</div>
						</div>
					</div>
					<div className="clear"></div>
				</div>
			</div>
		</>
	);
}

export default DashboardInfoBlocks;
